import { Menu, Home, SearchBox, Text, Header } from './components';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { alertActions, switchActions } from './actions'
import { history } from './helpers';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrivacyPolicyGaming } from './components/PrivacyPolicyGaming';
import { TermsOfUseGaming } from './components/TermsOfUseGaming';
import { GDPRDataRequest } from './components/GDPRDataRequest';
import { DiscussionPage } from './components/DiscussionPage';
import { Helmet } from "react-helmet";
import { SpacePage } from './components/SpaceList';
import { PageNotFound } from './components/PageNotFound';
import { OneDiscussionPage } from './components/OneDiscussionPage';
import { OneProfilPage } from './components/OneProfilPage';
import { RssFeedMessage } from './components/RSSFeedMessage';

export const ThemeContext = createContext(null)

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200
    }
  }
});

// million-ignore
const App = (props) => {
  const t = useSelector(state => state.themeswitch);
  const [theme, setTheme] = useState(t);
  if (theme === 'dark') {
    document.getElementsByTagName('html')[0].classList.remove('light')
    document.getElementsByTagName('body')[0].classList.remove('light')
    document.getElementById('root').classList.remove('light')

    document.getElementsByTagName('html')[0].classList.add(theme)
    document.getElementsByTagName('body')[0].classList.add(theme)
    document.getElementById('root').classList.add(theme)
  }else{
    document.getElementsByTagName('html')[0].classList.remove('dark')
    document.getElementsByTagName('body')[0].classList.remove('dark')
    document.getElementById('root').classList.remove('dark')

    document.getElementsByTagName('html')[0].classList.add(theme)
    document.getElementsByTagName('body')[0].classList.add(theme)
    document.getElementById('root').classList.add(theme)
  }


  const { dispatch } = props;

  history.listen((location, action) => {
    // clear alert on location change
    dispatch(alertActions.clear());
  });

  const changeTheme = () => {
    setTheme((curr) => curr === "light" ? "dark" : "light")
  }

  useEffect(() => {
    setTheme(t);
  }, [t])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div id={theme}>
        <MuiThemeProvider>
          <BrowserRouter>
            <Menu />
            <Header />
            <SearchBox />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privacy-policy" element={<Home />} />
              <Route path="/terms-of-use" element={<Home />} />
              <Route path="/mobil" element={<Home />} />
              <Route path="/about" element={<Home />} />
              <Route path="/socialsearcher" element={<Home />} />
              <Route path="/breach" element={<Home />} />
              <Route path="/space" element={<Home />} />
              <Route path="/es" element={<Home />} />
              <Route path="/de" element={<Home />} />
              <Route path="/fr" element={<Home />} />
              <Route path="/pt" element={<Home />} />
              <Route path="/hi" element={<Home />} />
              <Route path="/indo" element={<Home />} />
              <Route path="/ru" element={<Home />} />
              <Route path="/ch" element={<Home />} />
              <Route path="/jp" element={<Home />} />
              <Route path="/ar" element={<Home />} />
              <Route path="/it" element={<Home />} />
              <Route path="/tr" element={<Home />} />
              <Route path="/nl" element={<Home />} />
              <Route path="/gr" element={<Home />} />
              <Route path="/gaming/roosbreaker" element={<Home />} />
              <Route path="/gaming/privacy-policy" element={<PrivacyPolicyGaming />}/>
              <Route path="/gaming/terms-of-use" element={<TermsOfUseGaming />} />
              <Route path="/gdpr-data-request" element={<GDPRDataRequest />} />             
              <Route path="/space/:space" element={<DiscussionPage />} />
              <Route path="/space/discussion/:discussion" element={<OneDiscussionPage />} />
              <Route path="/space/profil/:profil" element={<OneProfilPage />} />
              <Route path="/space/spaces" element={<SpacePage />} />
              <Route path="/news" element={<Home />} />
              <Route path="/rss/how-to-subscribe" element={<RssFeedMessage />} />
              <Route path="/rss/space" element={<RssFeedMessage />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/publishers" element={<Home />} />
            </Routes>
          </BrowserRouter>
        </MuiThemeProvider>
      </div>
    </ThemeContext.Provider>
  );
}

const connectedApp = connect(null)(App);
export { connectedApp as App };
