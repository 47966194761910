import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { appreciationActions, discussionActions, infoActions, listonespaceswitchActions, messageActions, socialMediaActions, spaceActions, spaceswitchActions, switchActions } from '../actions';
import { SocialMedia, SocialMediaNews } from './Item';
import { CustomPagination } from './CustomPagination';
import { languageswitchConstants, listonespaceswitchConstants, socialmediaConstants, spaceswitchConstants, switchConstants } from '../constants';
import { Spinner } from '../spinner/spinner';
import Swal from 'sweetalert2';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SocialMediaListRender } from './SocialMediaListRender';
import { SpaceListRender } from './SpaceListRender';
import { DiscussionListRender } from './DiscussionListRender';
import { ProfilListRender } from './ProfilListRender';
import { WrapperWeather } from './WrapperWeather';
import { WrapperRow } from './Row';


export function SpacePage() {
    const dispatch = useDispatch();
    // const { space } = useParams();
    // spacename = space;
    // console.log(space)

    useEffect(() => {
        dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
        dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
        dispatch(switchActions.changeTab(switchConstants.SPACE))
        // dispatch(discussionActions.getDiscussionList(space));
        dispatch(getAllInfosForSpace())
        // dispatch(discussionActions.getFeed());
    }, []);

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(spaceActions.getSpaceList());
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    return (
        <WrapperRow LinksCol={WrapperWeather} RightCol={SpaceList}>
        </WrapperRow>
    );
}

export const SpaceList = () => {
    const dispatch = useDispatch();
    const s = useSelector(state => state.space.spaceList);
    const [spaceList, setSpaceList] = useState(s);
    const loading = useSelector(state => state.space.loading);
    const e = useSelector(state => state.space.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.space.done);
    const [done, setDone] = useState(d);
    var dataLimitPerPage = 100;

    const ds = useSelector(state => state.discussion.discussionList);
    const [discussionList, setDiscussionList] = useState(ds);
    const loadingd = useSelector(state => state.discussion.loading);
    const ed = useSelector(state => state.discussion.error);
    const [errord, setErrord] = useState(ed);
    const dd = useSelector(state => state.discussion.done);
    const [doned, setDoned] = useState(dd);
    var dataLimitPerPageDi = 34;

    const p = useSelector(state => state.profil.profilList);
    const [profilList, setProfilList] = useState(p);
    const loadingp = useSelector(state => state.profil.loading);
    const ep = useSelector(state => state.profil.error);
    const [errorp, setErrorp] = useState(ep);
    const dp = useSelector(state => state.profil.done);
    const [donep, setDonep] = useState(dp);
    var dataLimitPerPageDi = 34;

    const ss = useSelector(state => state.spaceswitch);

    return (
        <>
            {ss == spaceswitchConstants.ALL_SPACES_NR && <SpaceListRender
                stateList={s}
                spaceList={spaceList}
                setSpaceList={setSpaceList}
                loading={loading}
                errorState={e}
                error={error}
                setError={setError}
                doneState={d}
                done={done}
                setDone={setDone}
                dataLimitPerPage={dataLimitPerPage}
            />}

            {ss == spaceswitchConstants.FEED_NR ||
                ss == spaceswitchConstants.MY_SPACE_NR ||
                ss == spaceswitchConstants.LIGHT_NR ?
                <DiscussionListRender
                    stateList={ds}
                    discussionList={discussionList}
                    setDiscussionList={setDiscussionList}
                    loading={loadingd}
                    errorState={ed}
                    error={errord}
                    setError={setErrord}
                    doneState={dd}
                    done={doned}
                    setDone={setDoned}
                    dataLimitPerPage={dataLimitPerPage}
                /> : null}           

            {ss == spaceswitchConstants.STAR_NR ||
                ss == spaceswitchConstants.MESSAGE_NR ||
                ss == spaceswitchConstants.FIND_NR ?
                <ProfilListRender
                    stateList={p}
                    profilList={profilList}
                    setProfilList={setProfilList}
                    loading={loadingp}
                    errorState={ep}
                    error={errorp}
                    setError={setErrorp}
                    doneState={dp}
                    done={donep}
                    setDone={setDonep}
                    dataLimitPerPage={dataLimitPerPage}
                /> : null}
        </>
    );
}




