import { Badge, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, InputAdornment, Paper, TextField, Typography, Stack, Container, ImageList, ImageListItem, FormControl, InputLabel, Select, MenuItem, Grid, IconButton, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from "react-responsive-carousel";
import { pageActions } from '../actions/page.actions';
import { pageConstants } from '../constants';
import { Weather } from './Weather';
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import { Responsive, WidthProvider } from "react-grid-layout";
import { Post, Additional, PostPreview, CreatePost, DraggableLoadingPopup } from './SpaceItem';
import { SyncLoader } from 'react-spinners';
import { Spinner } from '../spinner/spinner';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { styled } from '@mui/system';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdsComponent } from "./AdsComponent";

const ResponsiveGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

export const CustomPagination = ({ data, RenderComponent, title, pageLimit, dataLimit }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [pages] = useState(data.length % dataLimit === 0 ? data.length / dataLimit : Math.floor(data.length / dataLimit) + 1);
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const p = useSelector(state => state.page);
    const [page, setPage] = useState(p);
    const [currentPage, setCurrentPage] = useState(page);

    const { height, width } = useWindowDimensions();

    // console.log(localStorage.getItem("rgl-8"))
    // console.log(getLayout(dataLimit))

    const [layouts, setLayouts] = useState({ layouts: JSON.parse(JSON.stringify(originalLayouts)) });

    const ls = useSelector(state => state.socialmedia.loading);
    const [socialLoading, setSocialLoading] = useState(ls);
    const lses = useSelector(state => state.socialmediaES.loading);
    const [socialLoadingES, setSocialLoadingES] = useState(lses);
    const lsde = useSelector(state => state.socialmediaDE.loading);
    const [socialLoadingDE, setSocialLoadingDE] = useState(lsde);
    const lsfr = useSelector(state => state.socialmediaFR.loading);
    const [socialLoadingFR, setSocialLoadingFR] = useState(lsfr);
    const lspt = useSelector(state => state.socialmediaPT.loading);
    const [socialLoadingPT, setSocialLoadingPT] = useState(lspt);
    const lshi = useSelector(state => state.socialmediaHI.loading);
    const [socialLoadingHI, setSocialLoadingHI] = useState(lshi);
    const lsindo = useSelector(state => state.socialmediaINDO.loading);
    const [socialLoadingINDO, setSocialLoadingINDO] = useState(lsindo);
    const lsru = useSelector(state => state.socialmediaRU.loading);
    const [socialLoadingRU, setSocialLoadingRU] = useState(lsru);
    const lsch = useSelector(state => state.socialmediaCH.loading);
    const [socialLoadingCH, setSocialLoadingCH] = useState(lsch);
    const lsjp = useSelector(state => state.socialmediaJP.loading);
    const [socialLoadingJP, setSocialLoadingJP] = useState(lsjp);
    const lsar = useSelector(state => state.socialmediaAR.loading);
    const [socialLoadingAR, setSocialLoadingAR] = useState(lsar);
    const lsit = useSelector(state => state.socialmediaIT.loading);
    const [socialLoadingIT, setSocialLoadingIT] = useState(lsit);
    const lstr = useSelector(state => state.socialmediaTR.loading);
    const [socialLoadingTR, setSocialLoadingTR] = useState(lstr);
    const lsnl = useSelector(state => state.socialmediaNL.loading);
    const [socialLoadingNL, setSocialLoadingNL] = useState(lsnl);
    const lsgr = useSelector(state => state.socialmediaGR.loading);
    const [socialLoadingGR, setSocialLoadingGR] = useState(lsgr);
    const lmya = useSelector(state => state.myappreciation.loading);
    const [loadingMyAppreciation, setLoadingMyAppreciation] = useState(lmya);
    const lmess = useSelector(state => state.message.loading);
    const [loadingMessage, setLoadingMessage] = useState(lmess);
    const linf = useSelector(state => state.info.loading);
    const [loadingInfo, setLoadingInfo] = useState(linf);
    const lq = useSelector(state => state.quote.loading);
    const [loadingQuote, setLoadingQuote] = useState(lq);
    const lp = useSelector(state => state.publisher.loading);
    const [loadingPublisher, setLoadingPublisher] = useState(lp);

    const [openLoadingPopup, setOpenLoadingPopup] = useState(false);
    const handleOpenLoadingPopup = (e) => {
        setOpenLoadingPopup(true);
    }
    const handleCloseLoadingPopup = () => {
        setOpenLoadingPopup(false);
    }

    const [loading, setLoading] = useState(false);
    const WAIT_CHANGE = 2000;

    const interval = () => setTimeout(() => {
        setLoading(false);
    }, WAIT_CHANGE);

    // console.log(layout)

    // useEffect(() => {
    //     localStorage.setItem("layout", JSON.stringify(layout));
    // }, [layout]);

    const onLayoutChange = (layout, layouts) => {
        saveToLS("layouts", layouts);
        setLayouts({ layouts });
    }


    useEffect(() => {
        localStorage.setItem("page", p)
        setPage(p);
    }, [p])

    function goToNextPage() {
        if (page < pages) {
            dispatch(pageActions.changePage(pageConstants.NEXT))
            // setCurrentPage(page);

            scrollToTop();
        }
    }

    function goToPreviousPage() {
        if (page > 1) {
            dispatch(pageActions.changePage(pageConstants.PREVIOUS))
            // setCurrentPage(page);

            scrollToTop();
        }
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        // setCurrentPage(pageNumber);
        dispatch(pageActions.changePage(pageConstants.CURRENT, pageNumber))
        scrollToTop();
    }

    const getPaginatedData = () => {
        const startIndex = page * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
        let start = Math.floor((page - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    const scrollToTop = () => {
        setLoading(true)

        goToTop()

        interval() // For making good the transition between pages
    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        setSocialLoading(ls);
    }, [ls]);

    useEffect(() => {
        setSocialLoadingES(lses);
    }, [lses]);

    useEffect(() => {
        setSocialLoadingDE(lsde);
    }, [lsde]);

    useEffect(() => {
        setSocialLoadingFR(lsfr);
    }, [lsfr]);

    useEffect(() => {
        setSocialLoadingPT(lspt);
    }, [lspt]);

    useEffect(() => {
        setSocialLoadingHI(lshi);
    }, [lshi]);

    useEffect(() => {
        setSocialLoadingINDO(lsindo);
    }, [lsindo]);

    useEffect(() => {
        setSocialLoadingRU(lsru);
    }, [lsru]);

    useEffect(() => {
        setSocialLoadingCH(lsch);
    }, [lsch]);

    useEffect(() => {
        setSocialLoadingJP(lsjp);
    }, [lsjp]);

    useEffect(() => {
        setSocialLoadingAR(lsar);
    }, [lsar]);

    useEffect(() => {
        setSocialLoadingIT(lsit);
    }, [lsit]);

    useEffect(() => {
        setSocialLoadingTR(lstr);
    }, [lstr]);

    useEffect(() => {
        setSocialLoadingNL(lsnl);
    }, [lsnl]);

    useEffect(() => {
        setSocialLoadingGR(lsgr);
    }, [lsgr]);

    useEffect(() => {
        setLoadingMyAppreciation(lmya);
    }, [lmya]);

    useEffect(() => {
        setLoadingMessage(lmess);
    }, [lmess]);

    useEffect(() => {
        setLoadingInfo(linf);
    }, [linf]);

    useEffect(() => {
        setLoadingQuote(lq);
    }, [lq]);

    useEffect(() => {
        setLoadingPublisher(lp);
    }, [lp]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var rows = 0;

    return (
        <>
            <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                    <div>

                        <Spinner show={loading} />

                        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >
                                {title} {<ButtonSpinner handleOpenLoadingPopup={handleOpenLoadingPopup} show={
                                    socialLoading ||
                                    socialLoadingES ||
                                    socialLoadingDE ||
                                    socialLoadingFR ||
                                    socialLoadingPT ||
                                    socialLoadingHI ||
                                    socialLoadingINDO ||
                                    socialLoadingRU ||
                                    socialLoadingCH ||
                                    socialLoadingJP ||
                                    socialLoadingAR ||
                                    socialLoadingIT ||
                                    socialLoadingTR ||
                                    socialLoadingNL ||
                                    socialLoadingGR ||
                                    loadingMyAppreciation ||
                                    loadingMessage ||
                                    loadingInfo ||
                                    loadingQuote ||
                                    loadingPublisher
                                } />}
                            </h1> {/* light */}
                        </div>

                        <DraggableLoadingPopup openLoadingPopup={openLoadingPopup} handleCloseLoadingPopup={handleCloseLoadingPopup} />

                        {/* show the posts, 10 posts at a time */}
                        <Grid
                            container
                            spacing={4}
                            // className="marginLaptop"
                            justifyItems="center"
                        // style={{ PaddingRight: "1000px" }}
                        >
                            {getPaginatedData().map((d, idx) => (
                                <Grid key={idx} item xs={12} sm={12} md={12} lg={6} xl={4}>
                                    <RenderComponent key={idx} index={idx} data={d} />
                                </Grid>
                            ))}
                        </Grid>
                        {/* <div>
                            {getPaginatedData().map((d, idx) => (
                                <RenderComponent key={idx} data={d} />
                            ))}
                        </div> */}

                        {/* show the pagiantion
        it consists of next and previous buttons
        along with page numbers, in our case, 5 page
        numbers at a time
    */}
                        {pages > 1 && getPaginatedData() && getPaginatedData().length >= 1 ?
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={5}
                            // style={{ paddingBottom: 100, marginBottom: 70 }}
                            >
                                {page > 1 ? <Item>
                                    <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={goToPreviousPage} title="Previous">
                                        <ArrowCircleLeftIcon fontSize="large" />
                                    </IconButton>
                                </Item> : null}
                                {page < pages ? <Item>
                                    <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={goToNextPage} title="Next">
                                        <ArrowCircleRightIcon fontSize="large" />
                                    </IconButton>
                                </Item> : null}
                            </Stack> : null}

                        {/* <div className="pagination" style={{ paddingBottom: 25, paddingTop: width < 500 ? 25 : 50, paddingRight: "25px", marginTop: 5 }}> */}
                        {/* previous button */}
                        {/* <button
                                    onClick={goToPreviousPage}
                                    className={`prev ${page === 1 ? 'disabled' : ''}`}
                                    style={{ borderRadius: 20 }}
                                >
                                    prev
                                </button> */}


                        {/* show page numbers */}
                        {/* {getPaginationGroup().map((item, index) => (
                                    <span
                                        key={index}
                                        onClick={changePage}
                                        className={`paginationItem ${page === item ? 'active' : null}`}
                                    >
                                        <span>{item}</span>
                                    </span>
                                ))} */}

                        {/* next button */}

                        {/* <button
                                    onClick={goToNextPage}
                                    className={`next ${page === pages ? 'disabled' : ''}`}
                                    style={{ borderRadius: 20 }}
                                >
                                    next
                                </button> */}
                        {/* </div> : null} */}

                        {getPaginatedData() && getPaginatedData().length >= 1 ? <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={0}
                            p={1}
                            style={{ paddingBottom: 10, marginBottom: 15, paddingTop: pages === 1 ? 50 : 0 }}
                        >
                            <Item>
                                <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={() => goToTop()} title="Up">
                                    <ArrowCircleUpIcon fontSize="large" />
                                </IconButton>
                            </Item>
                        </Stack> : null}

                        {/* <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}
                            p={1}                     
                        >
                            <Item>
                                <button swg-standard-button="contribution"></button>
                            </Item>
                        </Stack> */}

                        {getPaginatedData() && getPaginatedData().length >= 1 ?
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={1}
                                style={{ paddingBottom: 10, marginBottom: 15, paddingTop: pages === 1 ? 50 : 0 }}
                            >
                                {<Item><AdsComponent dataAdSlot='5472839718' currentPath={location.pathname} width={width >= 1200 ? 360 : 260} /></Item>}

                                {width >= 1200 ?
                                    <Item><AdsComponent dataAdSlot='5476709145' currentPath={location.pathname} width={360} /></Item>
                                    : null}

                                {width >= 1482 ?
                                    <Item><AdsComponent dataAdSlot='8931108113' currentPath={location.pathname} width={360} /></Item>
                                    : null}
                            </Stack> : null}


                        {<div style={{ paddingBottom: width < 500 ? 25 : 100, paddingTop: width < 500 ? 25 : 50, /*paddingRight: "25px",*/ marginTop: 5, marginBottom: 70 }} />}


                    </div>
                </div>
            </div>
        </>
    );
}

export const ButtonSpinner = ({ handleOpenLoadingPopup, show }) => {

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <>
            {show ?
                <span style={{ cursor: "pointer" }} onClick={handleOpenLoadingPopup}>
                    <SyncLoader size={'10px'} color={theme === 'dark' ? '#fff' : '#000'} />
                </span>
                : null}
        </>
    );
}

const getLayout = (numData) => {
    // console.log(numData)
    // console.log(window.innerWidth)

    if (numData === 15 && window.innerWidth >= 1360) {
        console.log(3)
        return [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 2, y: 0, w: 1, h: 1 },
            { i: "3", x: 0, y: 1, w: 1, h: 1 },
            { i: "4", x: 1, y: 1, w: 1, h: 1 },
            { i: "5", x: 2, y: 1, w: 1, h: 1 },
            { i: "6", x: 0, y: 2, w: 1, h: 1 },
            { i: "7", x: 1, y: 2, w: 1, h: 1 },
            { i: "8", x: 2, y: 2, w: 1, h: 1 },
            { i: "9", x: 0, y: 3, w: 1, h: 1 },
            { i: "10", x: 1, y: 3, w: 1, h: 1 },
            { i: "11", x: 2, y: 3, w: 1, h: 1 },
            { i: "12", x: 0, y: 4, w: 1, h: 1 },
            { i: "13", x: 1, y: 4, w: 1, h: 1 },
            { i: "14", x: 2, y: 4, w: 1, h: 1 }
        ]
    } else if (numData === 15 && window.innerWidth < 1200) {
        console.log(1)
        return [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 2, y: 0, w: 1, h: 1 },
            { i: "3", x: 0, y: 1, w: 1, h: 1 },
            { i: "4", x: 1, y: 1, w: 1, h: 1 },
            { i: "5", x: 2, y: 1, w: 1, h: 1 },
            { i: "6", x: 0, y: 2, w: 1, h: 1 },
            { i: "7", x: 1, y: 2, w: 1, h: 1 },
            { i: "8", x: 2, y: 2, w: 1, h: 1 },
            { i: "9", x: 0, y: 3, w: 1, h: 1 },
            { i: "10", x: 1, y: 3, w: 1, h: 1 },
            { i: "11", x: 2, y: 3, w: 1, h: 1 },
            { i: "12", x: 0, y: 4, w: 1, h: 1 },
            { i: "13", x: 1, y: 4, w: 1, h: 1 },
            { i: "14", x: 2, y: 4, w: 1, h: 1 }
        ]
    } else if (numData === 14 && window.innerWidth >= 1200 && window.innerWidth < 1360) {
        console.log(2)
        return [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 0, y: 1, w: 1, h: 1 },
            { i: "3", x: 1, y: 1, w: 1, h: 1 },
            { i: "4", x: 0, y: 2, w: 1, h: 1 },
            { i: "5", x: 1, y: 2, w: 1, h: 1 },
            { i: "6", x: 0, y: 3, w: 1, h: 1 },
            { i: "7", x: 1, y: 3, w: 1, h: 1 },
            { i: "8", x: 0, y: 4, w: 1, h: 1 },
            { i: "9", x: 1, y: 4, w: 1, h: 1 },
            { i: "10", x: 0, y: 5, w: 1, h: 1 },
            { i: "11", x: 1, y: 5, w: 1, h: 1 },
            { i: "12", x: 0, y: 6, w: 1, h: 1 },
            { i: "13", x: 1, y: 6, w: 1, h: 1 }
        ]
    }
}

const getLayoutForTwoColumns = () => {

    return [
        { i: "0", x: 0, y: 0, w: 1, h: 1 },
        { i: "1", x: 1, y: 0, w: 1, h: 1 },
        { i: "2", x: 0, y: 1, w: 1, h: 1 },
        { i: "3", x: 1, y: 1, w: 1, h: 1 },
        { i: "4", x: 0, y: 2, w: 1, h: 1 },
        { i: "5", x: 1, y: 2, w: 1, h: 1 },
        { i: "6", x: 0, y: 3, w: 1, h: 1 },
        { i: "7", x: 1, y: 3, w: 1, h: 1 },
        { i: "8", x: 0, y: 4, w: 1, h: 1 },
        { i: "9", x: 1, y: 4, w: 1, h: 1 },
        { i: "10", x: 0, y: 5, w: 1, h: 1 },
        { i: "11", x: 1, y: 5, w: 1, h: 1 },
        { i: "12", x: 0, y: 6, w: 1, h: 1 },
        { i: "13", x: 1, y: 6, w: 1, h: 1 }
    ]
}

const getLayoutForOneColumn = () => {

    return [
        { i: "0", x: 0, y: 0, w: 1, h: 1 },
        { i: "1", x: 1, y: 0, w: 1, h: 1 },
        { i: "2", x: 2, y: 0, w: 1, h: 1 },
        { i: "3", x: 3, y: 0, w: 1, h: 1 },
        { i: "4", x: 4, y: 0, w: 1, h: 1 },
        { i: "5", x: 5, y: 0, w: 1, h: 1 },
        { i: "6", x: 6, y: 0, w: 1, h: 1 },
        { i: "7", x: 7, y: 0, w: 1, h: 1 },
        { i: "8", x: 8, y: 0, w: 1, h: 1 },
        { i: "9", x: 9, y: 0, w: 1, h: 1 },
        { i: "10", x: 10, y: 0, w: 1, h: 1 },
        { i: "11", x: 11, y: 0, w: 1, h: 1 },
        { i: "12", x: 12, y: 0, w: 1, h: 1 },
        { i: "13", x: 13, y: 0, w: 1, h: 1 },
        { i: "14", x: 14, y: 0, w: 1, h: 1 }
    ]
}

function getFromLS(key) {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem("rgl-8")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (localStorage) {
        localStorage.setItem(
            "rgl-8",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const layout = [
    { i: "0", x: 0, y: 0, w: 1, h: 1 },
    { i: "1", x: 1, y: 0, w: 1, h: 1 },
    { i: "2", x: 2, y: 0, w: 1, h: 1 },
    { i: "3", x: 0, y: 1, w: 1, h: 1 },
    { i: "4", x: 1, y: 1, w: 1, h: 1 },
    { i: "5", x: 2, y: 1, w: 1, h: 1 },
    { i: "6", x: 0, y: 2, w: 1, h: 1 },
    { i: "7", x: 1, y: 2, w: 1, h: 1 },
    { i: "8", x: 2, y: 2, w: 1, h: 1 },
    { i: "9", x: 0, y: 3, w: 1, h: 1 },
    { i: "10", x: 1, y: 3, w: 1, h: 1 },
    { i: "11", x: 2, y: 3, w: 1, h: 1 },
    { i: "12", x: 0, y: 4, w: 1, h: 1 },
    { i: "13", x: 1, y: 4, w: 1, h: 1 },
    { i: "14", x: 2, y: 4, w: 1, h: 1 }
];

const getLayouts = () => {
    const savedLayouts = localStorage.getItem("grid-layout");

    return savedLayouts ? JSON.parse(savedLayouts) : { lg: layout };
};
// styled-components definition removed for brevity...

const handleLayoutChange = (layout, layouts) => {
    localStorage.setItem("grid-layout", JSON.stringify(layouts));
};


// const aaaa = () => {
//     return (
//         <>
//             <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
//                 <div id="top" class="marginMobil" style={{ marginRight: "30px", marginLeft: window.innerWidth >= 600 && window.innerWidth < 1200 ? "100px" : "0px" }}>
//                     <div>
//                         <div style={{ paddingTop: 30, paddingBottom: 30 }}>
//                             <a href="#top" className="white" style={{ fontSize: "2.5rem", color: theme === 'dark' ? '#fff' : '#000', textDecoration: "none" }} >{title}</a> {/* light */}
//                         </div>

//                         {/* show the posts, 10 posts at a time */}
//                         <ResponsiveGridLayout
//                             className='layout'
//                             rowHeight={650}
//                             breakpoints={{ xl: 1482, lg: 1200, md: 900, sm: 600, xs: 480, xxs: 0 }}
//                             layouts={layouts}
//                             onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
//                             cols={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1, xxs: 1 }}
//                             margin={[10, 10]}
//                             draggableHandle='.draggable'
//                             isResizable={false}
//                         >
//                             {getPaginatedData().map((d, idx) => {
//                                 {/* <Grid key={idx} item xs={12} sm={12} md={12} lg={6} xl={4}> */ }
//                                 var columns;
//                                 //console.log(window.innerWidth)
//                                 if (window.innerWidth < 1200) {
//                                     // console.log(1)
//                                     columns = 1;
//                                 } else if (window.innerWidth >= 1200 && window.innerWidth < 1482) {
//                                     // console.log(2)
//                                     columns = 2;
//                                 } else if (window.innerWidth >= 1482) {
//                                     // console.log(3)
//                                     columns = 3;
//                                 }

//                                 var cx = idx % columns;

//                                 return <div key={idx} data-grid={{ w: 1, h: 1, x: cx, y: cx === 0 && idx != 0 ? rows++ : rows }}><RenderComponent key={idx} index={idx} data={d} /></div>
//                                 {/* </Grid> */ }
//                             })}
//                         </ResponsiveGridLayout>
//                         {/* <div>
//                             {getPaginatedData().map((d, idx) => (
//                                 <RenderComponent key={idx} data={d} />
//                             ))}
//                         </div> */}

//                         {/* show the pagiantion
//         it consists of next and previous buttons
//         along with page numbers, in our case, 5 page
//         numbers at a time
//     */}
//                         <div className="pagination" style={{ paddingBottom: window.innerWidth < 500 ? 25 : 100, paddingTop: window.innerWidth < 500 ? 25 : 50, paddingRight: "25px", marginTop: 5, marginBottom: 70 }}>
//                             {/* previous button */}
//                             <button
//                                 onClick={goToPreviousPage}
//                                 className={`prev ${page === 1 ? 'disabled' : ''}`}
//                                 style={{ borderRadius: 20 }}
//                             >
//                                 prev
//                             </button>

//                             {/* show page numbers */}
//                             {getPaginationGroup().map((item, index) => (
//                                 <span
//                                     key={index}
//                                     onClick={changePage}
//                                     className={`paginationItem ${page === item ? 'active' : null}`}
//                                 >
//                                     <span>{item}</span>
//                                 </span>
//                             ))}

//                             {/* next button */}
//                             <button
//                                 onClick={goToNextPage}
//                                 className={`next ${page === pages ? 'disabled' : ''}`}
//                                 style={{ borderRadius: 20 }}
//                             >
//                                 next
//                             </button>
//                         </div>
//                         <div class="container" style={{ marginBottom: 1 }}>
//                             {/* <div class="row"> */}
//                             {/* <div class="col" style={{ justifyContent: "center" }}><iframe src="//www.highperformancedisplayformat.com/watchnew?key=cfec91924de057282b3b66314a365372" width="300" height="250" frameborder="0" scrolling="no"></iframe></div>
//                                     <div class="col" style={{ justifyContent: "center" }}><iframe src="//www.highperformancedisplayformat.com/watchnew?key=e7a0d089c39b74e6b81ca7d7673cae15" width="300" height="250" frameborder="0" scrolling="no"></iframe></div>
//                                     <div class="col" style={{ justifyContent: "center" }}><iframe src="//www.highperformancedisplayformat.com/watchnew?key=9e69e85b6c22a2f7893a2bd472d36553" width="300" height="250" frameborder="0" scrolling="no"></iframe></div>
//                                     <div class="col" style={{ justifyContent: "center" }}><iframe src="//www.highperformancedisplayformat.com/watchnew?key=0aa582b51414f429a2aaf11f822c4a8f" width="300" height="250" frameborder="0" scrolling="no"></iframe></div> */}
//                             {/* </div> */}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// } 