import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { discussionActions, infoActions, listonespaceswitchActions, socialMediaActions, spaceActions, spaceswitchActions, switchActions } from '../actions';
import { SocialMedia, SocialMediaNews } from './Item';
import { CustomPagination } from './CustomPagination';
import { languageswitchConstants, listonespaceswitchConstants, socialmediaConstants, spaceswitchConstants, switchConstants } from '../constants';
import { Spinner } from '../spinner/spinner';
import Swal from 'sweetalert2';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SocialMediaListRender } from './SocialMediaListRender';
import { SpaceListRender, TopicListRender } from './SpaceListRender';
import { DiscussionListRender } from './DiscussionListRender';
import { WrapperRow } from './Row';
import { WrapperWeather } from './WrapperWeather';
import { useParams } from 'react-router-dom';
import { ProfilListRender } from './ProfilListRender';
import { Helmet } from 'react-helmet-async';
import { getEnv, truncateDescription } from '../helpers';
import { PageNotFound } from './PageNotFound';

const shareLink = true;

export function OneDiscussionPage() {
    const dispatch = useDispatch();
    const { discussion } = useParams();
    const ds = useSelector(state => state.discussion.discussionList);
    const d = useSelector(state => state.discussion.done);
    const [discussionList, setDiscussionList] = useState(ds);
    // spacename = space;
    // console.log(space)

    useEffect(() => {
        dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
        dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
        dispatch(switchActions.changeTab(switchConstants.SPACE))
        dispatch(getAllInfosForSpace()) 
        // dispatch(discussionActions.getFeed());
    }, []);

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(discussionActions.getDiscussionList(discussion, "", false));
                dispatch(spaceActions.getSpaceList());
                dispatch(infoActions.getInfos())
            })
        }
    }

    useEffect(() => {
        setDiscussionList(ds);
    }, [ds]);

    // var domain = window.location.hostname;

    // const environnement = getEnv(domain)

    return (
        <>
            {/* {discussionList && discussionList.discussions && discussionList.discussions.length === 1 &&
                <>
                    {discussionList.discussions[0] &&
                        <>
                            {
                                discussionList.discussions[0].images.length > 0 ?
                                    <SEO
                                        title={discussionList.discussions[0].title + " - OneLike Social"}
                                        description={truncateDescription(discussionList.discussions[0].post)}
                                        name={"OneLike Social"}
                                        twitterat={"@OneLikeApp"}
                                        type={"website"}
                                        url={`${environnement}${discussionList.discussions[0].path}`}
                                        image={discussionList.discussions[0].images[0].url}
                                        showimage={true}
                                    />
                                    :
                                    <SEO
                                        title={discussionList.discussions[0].title + " - OneLike Social"}
                                        description={truncateDescription(discussionList.discussions[0].post)}
                                        name={"OneLike"}
                                        twitterat={"@OneLikeApp"}
                                        type={"website"}
                                        url={`${environnement}${discussionList.discussions[0].path}`}
                                        image={""}
                                        showimage={false}
                                    />
                            }
                        </>
                    }
                </>
            } */}
            
            <WrapperRow LinksCol={WrapperWeather} RightCol={DiscussionList}>
            </WrapperRow>

            {/* {ds && ds.discussions && ds.discussions[0] ?
                <>
                    <WrapperRow LinksCol={WrapperWeather} RightCol={DiscussionList}>
                    </WrapperRow>
                </> :
                <PageNotFound />
            } */}
        </>
    );
}

export function SEO({ title, description, name, type, url, image, twitterat, showimage }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name="title" content={title} />
            <link rel="canonical" href={url} />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {showimage ? <meta property="og:image" content={image} /> : null}
            <meta property="og:site_name" content={name} />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:url" content={url} />
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {showimage ? <meta name="twitter:image" content={image} /> : null}
            <meta name="twitter:site" content={twitterat} />
            { /* End Twitter tags */}
        </Helmet>)
}

export function DiscussionList() {
    const { discussion } = useParams();
    var spacename = discussion;

    const ds = useSelector(state => state.discussion.discussionList);
    const [discussionList, setDiscussionList] = useState(ds);
    const loading = useSelector(state => state.discussion.loading);
    const e = useSelector(state => state.discussion.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.discussion.done);
    const [done, setDone] = useState(d);
    var dataLimitPerPage = 100;

    const p = useSelector(state => state.profil.profilList);
    const [profilList, setProfilList] = useState(p);
    const loadingp = useSelector(state => state.profil.loading);
    const ep = useSelector(state => state.profil.error);
    const [errorp, setErrorp] = useState(ep);
    const dp = useSelector(state => state.profil.done);
    const [donep, setDonep] = useState(dp);
    var dataLimitPerPageDi = 34;

    // const sp = useSelector(state => state.space.spaceList);
    // const [spaceList, setSpaceList] = useState(sp);
    // const loadingsp = useSelector(state => state.space.loading);
    // const esp = useSelector(state => state.space.error);
    // const [errorsp, setErrorsp] = useState(esp);
    // const dsp = useSelector(state => state.space.done);
    // const [donesp, setDonesp] = useState(dsp);
    // var dataLimitPerPage = 35;

    const ss = useSelector(state => state.spaceswitch);

    return <>
        {ss == spaceswitchConstants.FEED_NR ||
            ss == spaceswitchConstants.ALL_SPACES_NR ||
            ss == spaceswitchConstants.MY_SPACE_NR ||
            ss == spaceswitchConstants.LIGHT_NR ?
            <DiscussionListRender
                stateList={ds}
                discussionList={discussionList}
                setDiscussionList={setDiscussionList}
                loading={loading}
                errorState={e}
                error={error}
                setError={setError}
                doneState={d}
                done={done}
                setDone={setDone}
                dataLimitPerPage={dataLimitPerPage}
                spacename={spacename}
                shareLink={shareLink}
            /> : null}
        {ss == spaceswitchConstants.STAR_NR ||
            ss == spaceswitchConstants.MESSAGE_NR ||
            ss == spaceswitchConstants.FIND_NR ?
            <ProfilListRender
                stateList={p}
                profilList={profilList}
                setProfilList={setProfilList}
                loading={loadingp}
                errorState={ep}
                error={errorp}
                setError={setErrorp}
                doneState={dp}
                done={donep}
                setDone={setDonep}
                dataLimitPerPage={dataLimitPerPage}
            /> : null}
    </>
}