import { Button } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import dayjs from "dayjs";

export const Util = {
	transformWordToLowercase,
	extractDomain,
	isValidUrl,
	isValidHashtagString,
	formatHashtags,
	isOnlyWhitespace,
	truncateFullName,
	truncateDescription,
	getEnv,
	getEnvForProd,
	base64ToBlob,
	containsWhitespace,
	removeWhitespaces,
	shortGender,
	replaceWord,
	isTodayAfter
};

export const textToHTML = function (str) {

	// check for DOMParser support
	if (support) {
		var parser = new DOMParser();
		var doc = parser.parseFromString(str, 'text/html');
		return doc.body.innerHTML;
	}

	// Otherwise, create div and append HTML
	var dom = document.createElement('div');
	dom.innerHTML = str;
	return dom;

};

const support = (function () {
	if (!window.DOMParser) return false;
	var parser = new DOMParser();
	try {
		parser.parseFromString('x', 'text/html');
	} catch (err) {
		return false;
	}
	return true;
})();

export function transformWordToLowercase(word) {
	return word.replace(/\s+/g, '').toLowerCase();
}

export const ReadMoreReadLess = ({ limit, children }) => {
	
	const [isReadMoreShown, setReadMoreShown] = useState(false)

	const toggleBtn = () => {
		setReadMoreShown(prevState => !prevState)
	}

	return (
		<div>
			{isReadMoreShown ? children : children.substr(0, limit)}
			<Button onClick={toggleBtn}>{ isReadMoreShown ? 'Read Less' : '...Read More'}</Button>
		</div>
	);
}

export function extractDomain(url) {
	try {
		const parsedUrl = new URL(url.trim());
		return parsedUrl.hostname;
	} catch (error) {
		console.error("Invalid URL:", error);
		return null;
	}
}

export function isValidUrl(url) {
	// Trim the input and check if it is empty or only spaces
	const trimmedUrl = url.trim();
	if (trimmedUrl === '') {
		return true;
	}

	// Regular expression for URL validation
	const regexPattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
		'(([a-z\\d]([a-z\\d-]*[a-z\\d])*\\.)+[a-z]{2,})' + // domain name and TLD
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', // fragment locator
		'i' // case-insensitive
	);

	return regexPattern.test(trimmedUrl);
}

export function isValidHashtagString(input) {
	// Trim leading and trailing whitespaces and reduce multiple whitespaces to a single space
	const normalizedInput = input.trim().replace(/\s+/g, ' ');

	// Split the string into words
	const words = normalizedInput.split(' ');

	// Regex for valid hashtag and word
	const hashtagRegex = /^#[A-Za-z0-9]+$/;
	const wordRegex = /^[A-Za-z0-9]+$/;

	// Check each word
	for (const word of words) {
		// Skip empty words (which might occur if the original input had only spaces)
		if (word === '') continue;

		if (!hashtagRegex.test(word) && !wordRegex.test(word)) {
			return false;
		}
	}

	return true;
}

export function formatHashtags(input) {
	// Trim leading and trailing whitespaces and reduce multiple whitespaces to a single space
	const normalizedInput = input.trim().replace(/\s+/g, ' ');

	// Split the string into words
	const words = normalizedInput.split(' ');

	// Add # at the beginning of words that don't start with # and consist of [a-z, A-Z, 0-9]
	const formattedWords = words.map(word => {
		if (!word.startsWith('#') && /^[A-Za-z0-9]+$/.test(word)) {
			return '#' + word;
		} else {
			return word;
		}
	});

	// Join the words back into a single string
	return formattedWords.join(' ');
}

export function isOnlyWhitespace(word) {
	if (!word) {
		return true;
	}

	// Regular expression that matches only whitespace characters
	const whitespaceRegex = /^\s*$/;
	return whitespaceRegex.test(word);
}

export function containsWhitespace(str) {
	return /\s/.test(str);
}

export function removeWhitespaces(str) {
	if (str) {
		return str.replace(/\s+/g, '');
	}
}

export function truncateFullName(fullName) {
	if (fullName && fullName.length && fullName.length > 27) {
		return fullName.slice(0, 24) + '...';
	} else {
		return fullName;
	}
}

export function truncateDescription(description, limit) {
	if (description && description.length > limit) {
		return removeNewlines(description.slice(0, limit)) + '...';
	} else {
		return removeNewlines(description);
	}
}

function removeNewlines(str) {
	return str.replace(/\n/g, ' ');
}

export function getEnv(domain) {
	if (domain == "localhost") {
		return process.env.REACT_APP_PROD_ONELIKE_SPACE_FRONTEND;
	} else if (domain == "192.168.178.38") {
		return process.env.REACT_APP_PROD_ONELIKE_SPACE_FRONTEND;
	} else if (domain == "blue.onelike.app") {
		return process.env.REACT_APP_DEV_ONELIKE_SPACE_FRONTEND;
	} else if (domain == "social.onelike.app") {
		return process.env.REACT_APP_PROD_ONELIKE_SPACE_FRONTEND;
	} else {
		return process.env.REACT_APP_PROD_ONELIKE_SPACE_FRONTEND;
	}
}

export function getEnvForProd() {
	return process.env.REACT_APP_PROD_ONELIKE_SPACE_FRONTEND;
}

export function base64ToBlob(base64, mimeType) {
	const byteCharacters = atob(base64.split(',')[1]); // Decode base64
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], { type: mimeType });
}

export function shortGender(gender) {
	if (gender == "FEMALE") {
		return "F"
	} else if (gender == "MALE") {
		return "M"
	} 

	return "";
}

export function replaceWord(text, targetWord, replacementWord) {
	// Create a regular expression to find all occurrences of the target word
	// The 'gi' flags mean global (g) and case-insensitive (i)
	const regex = new RegExp(targetWord, 'gi');

	return text.replace(regex, replacementWord);
}

export function isTodayAfter(dateString) {
	// Parse the given date string
	const givenDate = dayjs(dateString);

	// Get today's date at the start of the day for a fair comparison
	const today = dayjs().startOf('day');

	// Check if today is after the given date
	return today.isAfter(givenDate);
}