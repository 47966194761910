import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { languageswitchActions, socialMediaActions, switchActions, spaceActions, listonespaceswitchActions, discussionActions, messageActions, appreciationActions, spaceswitchActions, publisherswitchActions } from '../actions';
import { SocialMediaList } from './SocialMediaList';
import { SocialMediaListES } from './SocialMediaListES';
import { SocialMediaListDE } from './SocialMediaListDE';
import { SocialMediaListFR } from './SocialMediaListFR';
import { SocialMediaListHI } from './SocialMediaListHI';
import { SocialMediaListINDO } from './SocialMediaListINDO';
import { AccountList } from './AccountList';
import { BreachList } from './BreachList';
import ReactGA from 'react-ga';
import { quoteActions } from "../actions/quote.actions";
import { About, Gaming, Mobil, PrivacyPolicy, TermsOfUse } from './Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { switchConstants, languageswitchConstants, listonespaceswitchConstants, spaceswitchConstants, publisherswitchConstants } from '../constants';
import { LanguageSwitch } from './Switch';
import { SocialMediaListPT } from './SocialMediaListPT';
import { SocialMediaListRU } from './SocialMediaListRU';
import { SocialMediaListCH } from './SocialMediaListCH';
import { SocialMediaListJP } from './SocialMediaListJP';
import { SocialMediaListAR } from './SocialMediaListAR';
import { SocialMediaListIT } from './SocialMediaListIT';
import { SocialMediaListTR } from './SocialMediaListTR';
import { SocialMediaListNL } from './SocialMediaListNL';
import { SocialMediaListGR } from './SocialMediaListGR';
import { socialMediaPreviewActions } from '../actions/socialmediapreview.actions';
import { WrapperRow } from './Row';
import { WrapperWeather } from './WrapperWeather';
import { Spinner } from '../spinner/spinner';
import { SpaceList } from './SpaceList';
import { Additional, CreatePost, Post, PostPreview } from './SpaceItem';
import { userActions } from '../actions/user.actions';
import { infoActions } from '../actions/info.actions';
import { Publisher } from './Publisher';
import { PublisherList } from './PublisherList';
import { publisherActions } from '../actions/publisher.actions';



ReactGA.initialize(process.env.REACT_APP_ONELIKE_GA_TRACKING_CODE);
export const Home = () => {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 350,
        height: 570,
        bgcolor: theme === 'dark' ? '#0D1F2D' : '#fff', // light
        color: theme === 'dark' ? '#fff' : '#000', // light
        border: theme === 'dark' ? "2px solid #fff" : "2px solid #000", // light
        borderRadius: "5px",
        boxShadow: 24,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1100000,
        p: 4,
        '@media (min-width: 600px)': {
            width: 500
        }
    };

    const style1 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 350,
        bgcolor: theme === 'dark' ? '#0D1F2D' : '#fff', // light
        color: theme === 'dark' ? '#fff' : '#000', // light
        border: theme === 'dark' ? "2px solid #fff" : "2px solid #000", // light
        borderRadius: "5px",
        boxShadow: 24,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1100000,
        p: 4,
        '@media (min-width: 600px)': {
            width: 500
        }
    };

    const style2 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 340,
        bgcolor: theme === 'dark' ? '#0D1F2D' : '#fff', // light
        color: theme === 'dark' ? '#fff' : '#000', // light
        border: theme === 'dark' ? "2px solid #fff" : "2px solid #000", // light
        borderRadius: "5px",
        boxShadow: 24,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1100000,
        p: 4,
    };

    // console.log("in Home")
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const s = useSelector(state => state.socialmedia.socialmediaList);
    const [socialmediaList, setSocialmedialList] = useState(s);
    const ses = useSelector(state => state.socialmediaES.socialmediaListES);
    const [socialmediaListES, setSocialmedialListES] = useState(ses);
    const sde = useSelector(state => state.socialmediaDE.socialmediaListDE);
    const [socialmediaListDE, setSocialmedialListDE] = useState(sde);
    const sfr = useSelector(state => state.socialmediaFR.socialmediaListFR);
    const [socialmediaListFR, setSocialmedialListFR] = useState(sfr);
    const spt = useSelector(state => state.socialmediaPT.socialmediaListPT);
    const [socialmediaListPT, setSocialmedialListPT] = useState(spt);
    const shi = useSelector(state => state.socialmediaHI.socialmediaListHI);
    const [socialmediaListHI, setSocialmedialListHI] = useState(shi);
    const sindo = useSelector(state => state.socialmediaINDO.socialmediaListINDO);
    const [socialmediaListINDO, setSocialmedialListINDO] = useState(sindo);
    const sru = useSelector(state => state.socialmediaRU.socialmediaListRU);
    const [socialmediaListRU, setSocialmedialListRU] = useState(sru);
    const sch = useSelector(state => state.socialmediaCH.socialmediaListCH);
    const [socialmediaListCH, setSocialmedialListCH] = useState(sch);
    const sjp = useSelector(state => state.socialmediaJP.socialmediaListJP);
    const [socialmediaListJP, setSocialmedialListJP] = useState(sjp);
    const sar = useSelector(state => state.socialmediaAR.socialmediaListAR);
    const [socialmediaListAR, setSocialmedialListAR] = useState(sar);
    const sit = useSelector(state => state.socialmediaIT.socialmediaListIT);
    const [socialmediaListIT, setSocialmedialListIT] = useState(sit);
    const str = useSelector(state => state.socialmediaTR.socialmediaListTR);
    const [socialmediaListTR, setSocialmedialListTR] = useState(str);
    const snl = useSelector(state => state.socialmediaNL.socialmediaListNL);
    const [socialmediaListNL, setSocialmedialListNL] = useState(snl);
    const sgr = useSelector(state => state.socialmediaGR.socialmediaListGR);
    const [socialmediaListGR, setSocialmedialListGR] = useState(sgr);

    const sp = useSelector(state => state.socialmediapreview.socialmediapreview);
    const [socialmediaPreview, setSocialmedialPreview] = useState(sp);

    const a = useSelector(state => state.account.accountList);
    const [accountList, setAccountList] = useState(a);

    const spc = useSelector(state => state.space.spaceList);
    const [spaceList, setSpaceList] = useState(spc);

    const lspc = useSelector(state => state.space.loading);
    const [spaceLoading, setSpaceLoading] = useState(lspc);

    const ms = useSelector(state => state.menuswitch);
    const [menuSwitch, setMenuSwitch] = useState(ms);
    const lang = useSelector(state => state.languageswitch);
    const [languageSwitch, setLanguageSwitch] = useState(lang);

    const ls = useSelector(state => state.socialmedia.loading);
    const [socialLoading, setSocialLoading] = useState(ls);
    const lses = useSelector(state => state.socialmediaES.loading);
    const [socialLoadingES, setSocialLoadingES] = useState(lses);
    const lsde = useSelector(state => state.socialmediaDE.loading);
    const [socialLoadingDE, setSocialLoadingDE] = useState(lsde);
    const lsfr = useSelector(state => state.socialmediaFR.loading);
    const [socialLoadingFR, setSocialLoadingFR] = useState(lsfr);
    const lspt = useSelector(state => state.socialmediaPT.loading);
    const [socialLoadingPT, setSocialLoadingPT] = useState(lspt);
    const lshi = useSelector(state => state.socialmediaHI.loading);
    const [socialLoadingHI, setSocialLoadingHI] = useState(lshi);
    const lsindo = useSelector(state => state.socialmediaINDO.loading);
    const [socialLoadingINDO, setSocialLoadingINDO] = useState(lsindo);
    const lsru = useSelector(state => state.socialmediaRU.loading);
    const [socialLoadingRU, setSocialLoadingRU] = useState(lsru);
    const lsch = useSelector(state => state.socialmediaCH.loading);
    const [socialLoadingCH, setSocialLoadingCH] = useState(lsch);
    const lsjp = useSelector(state => state.socialmediaJP.loading);
    const [socialLoadingJP, setSocialLoadingJP] = useState(lsjp);
    const lsar = useSelector(state => state.socialmediaAR.loading);
    const [socialLoadingAR, setSocialLoadingAR] = useState(lsar);
    const lsit = useSelector(state => state.socialmediaIT.loading);
    const [socialLoadingIT, setSocialLoadingIT] = useState(lsit);
    const lstr = useSelector(state => state.socialmediaTR.loading);
    const [socialLoadingTR, setSocialLoadingTR] = useState(lstr);
    const lsnl = useSelector(state => state.socialmediaNL.loading);
    const [socialLoadingNL, setSocialLoadingNL] = useState(lsnl);
    const lsgr = useSelector(state => state.socialmediaGR.loading);
    const [socialLoadingGR, setSocialLoadingGR] = useState(lsgr);

    const lb = useSelector(state => state.breach.loading);
    const [breachLoading, setBreachLoading] = useState(lb);
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const MINUTE_MS = 1200000;

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        navigate("/");
    }

    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => {
        setOpen1(false);
        navigate("/");
    }

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => {
        setOpen2(false);
        navigate("/");
    }

    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => {
        setOpen3(false);
        navigate("/");
    }

    const [open4, setOpen4] = useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => {
        setOpen4(false);
        navigate("/");
    }

    const [loading, setLoading] = useState(false);
    const WAIT_CHANGE = 2000;
    const interval = () => setTimeout(() => {
        setLoading(false);
    }, WAIT_CHANGE);

    useEffect(() => {
        dispatch(userActions.session());
    }, []);

    useEffect(() => {
        // console.log(location.pathname)
        // if (location.pathname === "" || location.pathname === "/") {
        //     var menu = localStorage.getItem("ms")

        //     if (menu == switchConstants.ACCOUNT_NR) {
        //         dispatch(switchActions.changeTab(switchConstants.SOCIAL_MEDIA));
        //         window.open("/")
        //     }

        //     if (menu == switchConstants.SPACE_NR) {
        //         dispatch(switchActions.changeTab(switchConstants.SPACE));
        //         window.open("/space")
        //     }

        //     if (menu == switchConstants.PUBLISHERS_NR) {
        //         dispatch(switchActions.changeTab(switchConstants.PUBLISHERS));
        //         window.open("/publishers")
        //     }
        // }

        if (location.pathname === "/privacy-policy" || location.pathname === "/privacy-policy/") {
            handleOpen();
        }
        if (location.pathname === "/terms-of-use" || location.pathname === "/terms-of-use/") {
            handleOpen1();
        }
        if (location.pathname === "/about" || location.pathname === "/about/") {
            handleOpen2();
        }
        if (location.pathname === "/mobil" || location.pathname === "/mobil/") {
            handleOpen3();
        }
        if (location.pathname === "/gaming/roosbreaker" || location.pathname === "/gaming/roosbreaker/") {
            handleOpen4();
        }
        if (location.pathname === "/socialsearcher" || location.pathname === "/socialsearcher/") {
            setLoading(true);
            resetPage();

            dispatch(switchActions.changeTab(switchConstants.ACCOUNT))
            
            interval();
        }
        if (location.pathname === "/breach" || location.pathname === "/breach/") {
            setLoading(true);
            resetPage();

            dispatch(switchActions.changeTab(switchConstants.HACKED))

            interval();
        }
        if (location.pathname === "/space" || location.pathname === "/space/") {
            setLoading(true);
            resetPage();

            dispatch(switchActions.changeTab(switchConstants.SPACE))
            dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
            dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
 
            interval();
        }
        if (location.pathname === "/news" || location.pathname === "/news/") {
            setLoading(true);
            resetPage();

            dispatch(switchActions.changeTab(switchConstants.SOCIAL_MEDIA))
            
            interval();
        }
        if (location.pathname === "/publishers" || location.pathname === "/publishers/") {
            setLoading(true);
            resetPage();

            dispatch(switchActions.changeTab(switchConstants.PUBLISHERS))
            dispatch(publisherswitchActions.showPublishers(publisherswitchConstants.ALL_PUBLISHERS))

            interval();
        }
    }, []);

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    // useEffect(() => {
    //     if(window.innerWidth >= 600 && window.innerWidth < 1200){
    //         dispatch(socialMediaActions.getSocialMediaList());
    //     }else if (window.innerWidth >= 1200){
    //         dispatch(socialMediaActions.getSocialMediaList());  
    //     }
    //     console.log("in window")
    // }, [windowSize]);

    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                if (window.innerWidth === 600) {
                    dispatch(getAllNews());
                } else if (window.innerWidth === 900) {
                    dispatch(getAllNews());
                } else if (window.innerWidth === 1200) {
                    dispatch(getAllNews());
                }
                setSize([window.innerWidth, window.innerHeight]);
            };
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    };

    useWindowSize();

    useEffect(() => {
        const interval = setInterval(() => {
            // Every 10 minutes, update news 
            dispatch(getAllNews());
            dispatch(getPersonalInfosForSpace());
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    useEffect(() => {
        setMenuSwitch(ms);
    }, [ms]);

    useEffect(() => {
        dispatch(getAllNews());
    }, []);

    useEffect(() => {
        dispatch(getAllInfosForSpace())
    }, []);

    const getAllNews = () => {
        // console.log(ms)
        // console.log("Dispatch LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL")

        return (dispatch, getState) => {
            batch(() => {
                dispatch(socialMediaActions.getSocialMediaList());
                dispatch(socialMediaActions.getSocialMediaListES());
                dispatch(socialMediaActions.getSocialMediaListDE());
                dispatch(socialMediaActions.getSocialMediaListFR());
                dispatch(socialMediaActions.getSocialMediaListPT());
                dispatch(socialMediaActions.getSocialMediaListHI());
                dispatch(socialMediaActions.getSocialMediaListINDO());
                dispatch(socialMediaActions.getSocialMediaListRU());
                dispatch(socialMediaActions.getSocialMediaListCH());
                dispatch(socialMediaActions.getSocialMediaListJP());
                dispatch(socialMediaActions.getSocialMediaListAR());
                dispatch(socialMediaActions.getSocialMediaListIT());
                dispatch(socialMediaActions.getSocialMediaListTR());
                dispatch(socialMediaActions.getSocialMediaListNL());
                dispatch(socialMediaActions.getSocialMediaListGR());
                dispatch(quoteActions.getQuotes());

                dispatch(publisherActions.getPublisherList())
                dispatch(infoActions.getInfoNewsGuide())
            })
        }
    }

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(discussionActions.getFeedFilter())
                dispatch(discussionActions.getFeed());
                dispatch(spaceActions.getSpaceList());
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    const getPersonalInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                // dispatch(discussionActions.getFeedFilter())
                // dispatch(discussionActions.getFeed());
                // dispatch(spaceActions.getSpaceList());
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    useEffect(() => {
        setSocialmedialList(s);
    }, [s]);

    useEffect(() => {
        setSocialmedialListES(ses);
    }, [ses]);

    useEffect(() => {
        setSocialmedialListDE(sde);
    }, [sde]);

    useEffect(() => {
        setSocialmedialListFR(sfr);
    }, [sfr]);

    useEffect(() => {
        setSocialmedialListPT(spt);
    }, [spt]);

    useEffect(() => {
        setSocialmedialListHI(shi);
    }, [shi]);

    useEffect(() => {
        setSocialmedialListINDO(sindo);
    }, [sindo]);

    useEffect(() => {
        setSocialmedialListRU(sru);
    }, [sru]);

    useEffect(() => {
        setSocialmedialListCH(sch);
    }, [sch]);

    useEffect(() => {
        setSocialmedialListJP(sjp);
    }, [sjp]);

    useEffect(() => {
        setSocialmedialListAR(sar);
    }, [sar]);

    useEffect(() => {
        setSocialmedialListIT(sit);
    }, [sit]);

    useEffect(() => {
        setSocialmedialListTR(str);
    }, [str]);

    useEffect(() => {
        setSocialmedialListNL(snl);
    }, [snl]);

    useEffect(() => {
        setSocialmedialListGR(sgr);
    }, [sgr]);

    useEffect(() => {
        setSpaceList(spc);
    }, [spc]);

    useEffect(() => {
        setSocialmedialPreview(sp);
    }, [sp]);

    // useEffect(() => {
    //     setLanguageSwitch(lang);
    // }, [lang]);

    useEffect(() => {
        setSocialLoading(ls);
    }, [ls]);

    useEffect(() => {
        setSocialLoadingES(lses);
    }, [lses]);

    useEffect(() => {
        setSocialLoadingDE(lsde);
    }, [lsde]);

    useEffect(() => {
        setSocialLoadingFR(lsfr);
    }, [lsfr]);

    useEffect(() => {
        setSocialLoadingPT(lspt);
    }, [lspt]);

    useEffect(() => {
        setSocialLoadingHI(lshi);
    }, [lshi]);

    useEffect(() => {
        setSocialLoadingINDO(lsindo);
    }, [lsindo]);

    useEffect(() => {
        setSocialLoadingRU(lsru);
    }, [lsru]);

    useEffect(() => {
        setSocialLoadingCH(lsch);
    }, [lsch]);

    useEffect(() => {
        setSocialLoadingJP(lsjp);
    }, [lsjp]);

    useEffect(() => {
        setSocialLoadingAR(lsar);
    }, [lsar]);

    useEffect(() => {
        setSocialLoadingIT(lsit);
    }, [lsit]);

    useEffect(() => {
        setSocialLoadingTR(lstr);
    }, [lstr]);

    useEffect(() => {
        setSocialLoadingNL(lsnl);
    }, [lsnl]);

    useEffect(() => {
        setSocialLoadingGR(lsgr);
    }, [lsgr]);

    useEffect(() => {
        setSpaceLoading(lspc);
    }, [lspc]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var show = menuSwitch;

    if (show === switchConstants.SOCIAL_MEDIA_NR) {
        // console.log(language)
        return (
            <>
                <WrapperRow LinksCol={WrapperWeather} RightCol={WrapperSocialMediaList}>
                </WrapperRow>
                <PrivacyPolicy open={open} handleClose={handleClose} customStyle={style} />
                <TermsOfUse open1={open1} handleClose1={handleClose1} customStyle={style} />
                <About open2={open2} handleClose2={handleClose2} customStyle={style1} />
                <Mobil open5={open3} handleClose5={handleClose3} customStyle={style1} />
                <Gaming open4={open4} handleClose4={handleClose4} customStyle={style2} />
            </>
        );
    } else if (show === switchConstants.ACCOUNT_NR) {
        return (
            <>
                {<Spinner show={loading} />}
                <WrapperRow LinksCol={WrapperWeather} RightCol={AccountList}>
                </WrapperRow>
            </>
        );
    } else if (show === switchConstants.HACKED_NR) {
        return (
            <>
                {<Spinner show={loading} />}
                <WrapperRow LinksCol={WrapperWeather} RightCol={BreachList}>
                </WrapperRow>
            </>
        );
    } else if (show === switchConstants.SPACE_NR) {
        return (
            <>
                {<Spinner show={loading} />}
                <WrapperRow LinksCol={WrapperWeather} RightCol={SpaceList}>
                </WrapperRow>              
            </>
        );
    } else if (show === switchConstants.PUBLISHERS_NR) {
        return (
            <>
                {<Spinner show={loading} />}
                <WrapperRow LinksCol={WrapperWeather} RightCol={PublisherList}>
                </WrapperRow>
            </>
        );
    }
}

const WrapperSocialMediaList = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const lang = useSelector(state => state.languageswitch);
    const [languageSwitch, setLanguageSwitch] = useState(lang);
    var language = languageSwitch;

    useEffect(() => {
        if (location.pathname === "/es") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.SPANISH))
        }
        if (location.pathname === "/de") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.DEUTSCH))
        }
        if (location.pathname === "/fr") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.FRANCAIS))
        }
        if (location.pathname === "/pt") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.PORTUGUESE))
        }
        if (location.pathname === "/hi") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.HINDI))
        }
        if (location.pathname === "/indo") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.INDONESIAN))
        }
        if (location.pathname === "/ru") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.RUSSIAN))
        }
        if (location.pathname === "/ch") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.CHINESE))
        }
        if (location.pathname === "/jp") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.JAPANESE))
        }
        if (location.pathname === "/ar") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.ARABIC))
        }
        if (location.pathname === "/it") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.ITALIAN))
        }
        if (location.pathname === "/tr") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.TURKISH))
        }
        if (location.pathname === "/nl") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.DUTCH))
        }
        if (location.pathname === "/gr") {
            dispatch(languageswitchActions.changeLanguage(languageswitchConstants.GREEK))
        }
    }, []);

    useEffect(() => {
        setLanguageSwitch(lang);
    }, [lang]);

    if (language === languageswitchConstants.ENGLISH_NR) {
        return (
            <>
                {/* <Spinner show={socialLoading} /> */}
                <SocialMediaList />
            </>

        );
    }

    if (language === languageswitchConstants.SPANISH_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingES} /> */}
                <SocialMediaListES />
            </>

        );
    }

    if (language === languageswitchConstants.DEUTSCH_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingDE} /> */}
                <SocialMediaListDE />
            </>

        );
    }

    if (language === languageswitchConstants.FRANCAIS_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingFR} /> */}
                <SocialMediaListFR />
            </>

        );
    }

    if (language === languageswitchConstants.PORTUGUESE_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingPT} /> */}
                <SocialMediaListPT />
            </>

        );
    }

    if (language === languageswitchConstants.HINDI_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingHI} /> */}
                <SocialMediaListHI />
            </>

        );
    }

    if (language === languageswitchConstants.INDONESIAN_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingINDO} /> */}
                <SocialMediaListINDO />
            </>

        );
    }

    if (language === languageswitchConstants.RUSSIAN_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingRU} /> */}
                <SocialMediaListRU />
            </>

        );
    }

    if (language === languageswitchConstants.CHINESE_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingCH} /> */}
                <SocialMediaListCH />
            </>

        );
    }

    if (language === languageswitchConstants.JAPANESE_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingJP} /> */}
                <SocialMediaListJP />
            </>

        );
    }

    if (language === languageswitchConstants.ARABIC_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingAR} /> */}
                <SocialMediaListAR />
            </>

        );
    }

    if (language === languageswitchConstants.ITALIAN_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingIT} /> */}
                <SocialMediaListIT />
            </>

        );
    }

    if (language === languageswitchConstants.TURKISH_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingTR} /> */}
                <SocialMediaListTR />
            </>

        );
    }

    if (language === languageswitchConstants.DUTCH_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingNL} /> */}
                <SocialMediaListNL />
            </>

        );
    }

    if (language === languageswitchConstants.GREEK_NR) {
        return (
            <>
                {/* <Spinner show={socialLoadingGR} /> */}
                <SocialMediaListGR />
            </>

        );
    }

    return (
        <>
            {/* <Spinner show={socialLoading} /> */}
            <SocialMediaList />
        </>

    );

    // return (
    //     <>
    //         {<Spinner show={socialLoading || socialLoadingES || socialLoadingDE || socialLoadingFR || socialLoadingPT || socialLoadingHI || socialLoadingINDO || socialLoadingRU || socialLoadingCH || socialLoadingJP || socialLoadingAR || socialLoadingIT || socialLoadingTR || socialLoadingNL || socialLoadingGR} />}
    //         {/* {<Spinner show={loading} />}
    //         {<Spinner show={socialLoading} />} */}
    //         {language === languageswitchConstants.ENGLISH_NR ? <SocialMediaList /> : <></>}
    //         {language === languageswitchConstants.SPANISH_NR ? <SocialMediaListES /> : <></>}
    //         {language === languageswitchConstants.DEUTSCH_NR ? <SocialMediaListDE /> : <></>}
    //         {language === languageswitchConstants.FRANCAIS_NR ? <SocialMediaListFR /> : <></>}
    //         {language === languageswitchConstants.PORTUGUESE_NR ? <SocialMediaListPT /> : <></>}
    //         {language === languageswitchConstants.HINDI_NR ? <SocialMediaListHI /> : <></>}
    //         {language === languageswitchConstants.INDONESIAN_NR ? <SocialMediaListINDO /> : <></>}
    //         {language === languageswitchConstants.RUSSIAN_NR ? <SocialMediaListRU /> : <></>}
    //         {language === languageswitchConstants.CHINESE_NR ? <SocialMediaListCH /> : <></>}
    //         {language === languageswitchConstants.JAPANESE_NR ? <SocialMediaListJP /> : <></>}
    //         {language === languageswitchConstants.ARABIC_NR ? <SocialMediaListAR /> : <></>}
    //         {language === languageswitchConstants.ITALIAN_NR ? <SocialMediaListIT /> : <></>}
    //         {language === languageswitchConstants.TURKISH_NR ? <SocialMediaListTR /> : <></>}
    //         {language === languageswitchConstants.DUTCH_NR ? <SocialMediaListNL /> : <></>}
    //         {language === languageswitchConstants.GREEK_NR ? <SocialMediaListGR /> : <></>}
    //     </>
    // );
}