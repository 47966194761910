import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { appreciationActions, discussionActions, infoActions, messageActions, profilActions, publisherActions, publisherswitchActions, quoteActions, socialMediaActions, spaceActions } from '../actions';
import { ButtonSpinner, CustomPagination } from './CustomPagination';
import { listonespaceswitchConstants, publisherswitchConstants, socialmediaConstants, spaceswitchConstants, switchConstants } from '../constants';
import { Spinner } from '../spinner/spinner';
import Swal from 'sweetalert2';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton } from '@material-ui/core';
import { DraggableLoadingPopup, PostPreview, PostPreview2, SpaceItem } from './SpaceItem';
import { CustomDiscussionPagination, LogoSpace } from './CustomDiscussionPagination';
import { CustomProfilPagination } from './CustomProfilPagination';
import { ProfilLayout } from './ProfilItem';

import StarIcon from '@mui/icons-material/Star';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import ForumIcon from '@mui/icons-material/Forum';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useWindowDimensions } from 'react-native';
import FeedIcon from '@mui/icons-material/Feed';


export const ProfilListRender = ({
    stateList,
    profilList,
    setProfilList,
    loading,
    errorState,
    error,
    setError,
    doneState,
    done,
    setDone,
    dataLimitPerPage,
    spacename,
    shareLink
}) => {

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const dispatch = useDispatch();
    const pw = useSelector(state => state.previewswitch);
    const [previewswitch, setPreviewSwitch] = useState(pw);
    const ss = useSelector(state => state.spaceswitch);
    const lop = useSelector(state => state.listonespaceswitch);

    const lin = useSelector((state) => state.authentication.loggedIn);
    const [loggedIn, setLoggedIn] = useState(lin);
    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);
    const authenticated = loggedIn && user;

    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))


    const ls = useSelector(state => state.socialmedia.loading);
    const [socialLoading, setSocialLoading] = useState(ls);
    const lses = useSelector(state => state.socialmediaES.loading);
    const [socialLoadingES, setSocialLoadingES] = useState(lses);
    const lsde = useSelector(state => state.socialmediaDE.loading);
    const [socialLoadingDE, setSocialLoadingDE] = useState(lsde);
    const lsfr = useSelector(state => state.socialmediaFR.loading);
    const [socialLoadingFR, setSocialLoadingFR] = useState(lsfr);
    const lspt = useSelector(state => state.socialmediaPT.loading);
    const [socialLoadingPT, setSocialLoadingPT] = useState(lspt);
    const lshi = useSelector(state => state.socialmediaHI.loading);
    const [socialLoadingHI, setSocialLoadingHI] = useState(lshi);
    const lsindo = useSelector(state => state.socialmediaINDO.loading);
    const [socialLoadingINDO, setSocialLoadingINDO] = useState(lsindo);
    const lsru = useSelector(state => state.socialmediaRU.loading);
    const [socialLoadingRU, setSocialLoadingRU] = useState(lsru);
    const lsch = useSelector(state => state.socialmediaCH.loading);
    const [socialLoadingCH, setSocialLoadingCH] = useState(lsch);
    const lsjp = useSelector(state => state.socialmediaJP.loading);
    const [socialLoadingJP, setSocialLoadingJP] = useState(lsjp);
    const lsar = useSelector(state => state.socialmediaAR.loading);
    const [socialLoadingAR, setSocialLoadingAR] = useState(lsar);
    const lsit = useSelector(state => state.socialmediaIT.loading);
    const [socialLoadingIT, setSocialLoadingIT] = useState(lsit);
    const lstr = useSelector(state => state.socialmediaTR.loading);
    const [socialLoadingTR, setSocialLoadingTR] = useState(lstr);
    const lsnl = useSelector(state => state.socialmediaNL.loading);
    const [socialLoadingNL, setSocialLoadingNL] = useState(lsnl);
    const lsgr = useSelector(state => state.socialmediaGR.loading);
    const [socialLoadingGR, setSocialLoadingGR] = useState(lsgr);
    const lmya = useSelector(state => state.myappreciation.loading);
    const [loadingMyAppreciation, setLoadingMyAppreciation] = useState(lmya);
    const lmess = useSelector(state => state.message.loading);
    const [loadingMessage, setLoadingMessage] = useState(lmess);
    const linf = useSelector(state => state.info.loading);
    const [loadingInfo, setLoadingInfo] = useState(linf);
    const lq = useSelector(state => state.quote.loading);
    const [loadingQuote, setLoadingQuote] = useState(lq);
    const lp = useSelector(state => state.publisher.loading);
    const [loadingPublisher, setLoadingPublisher] = useState(lp);
    const menuSwitch = useSelector(state => state.menuswitch);
    const publisherSwitch = useSelector(state => state.publisherswitch);

    const [openLoadingPopup, setOpenLoadingPopup] = useState(false);
    const handleOpenLoadingPopup = (e) => {
        setOpenLoadingPopup(true);
    }
    const handleCloseLoadingPopup = () => {
        setOpenLoadingPopup(false);
    }

    // if (window.innerWidth >= 900 && window.innerWidth < 1200) {
    //     dataLimitPerPage = 35;
    // } else if (window.innerWidth >= 1200 && window.innerWidth < 1482) {
    //     dataLimitPerPage = 34;
    // } else if (window.innerWidth >= 1482) {
    //     dataLimitPerPage = 34;
    // }

    const { width, height } = useWindowDimensions();

    useEffect(() => {
        setProfilList(stateList);
    }, [stateList]);

    useEffect(() => {
        setError(errorState);
    }, [errorState]);

    useEffect(() => {
        setDone(doneState);
    }, [doneState]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        localStorage.setItem("preview", pw)
        setPreviewSwitch(pw);
    }, [pw]);


    useEffect(() => {
        setSocialLoading(ls);
    }, [ls]);

    useEffect(() => {
        setSocialLoadingES(lses);
    }, [lses]);

    useEffect(() => {
        setSocialLoadingDE(lsde);
    }, [lsde]);

    useEffect(() => {
        setSocialLoadingFR(lsfr);
    }, [lsfr]);

    useEffect(() => {
        setSocialLoadingPT(lspt);
    }, [lspt]);

    useEffect(() => {
        setSocialLoadingHI(lshi);
    }, [lshi]);

    useEffect(() => {
        setSocialLoadingINDO(lsindo);
    }, [lsindo]);

    useEffect(() => {
        setSocialLoadingRU(lsru);
    }, [lsru]);

    useEffect(() => {
        setSocialLoadingCH(lsch);
    }, [lsch]);

    useEffect(() => {
        setSocialLoadingJP(lsjp);
    }, [lsjp]);

    useEffect(() => {
        setSocialLoadingAR(lsar);
    }, [lsar]);

    useEffect(() => {
        setSocialLoadingIT(lsit);
    }, [lsit]);

    useEffect(() => {
        setSocialLoadingTR(lstr);
    }, [lstr]);

    useEffect(() => {
        setSocialLoadingNL(lsnl);
    }, [lsnl]);

    useEffect(() => {
        setSocialLoadingGR(lsgr);
    }, [lsgr]);

    useEffect(() => {
        setLoadingMyAppreciation(lmya);
    }, [lmya]);

    useEffect(() => {
        setLoadingMessage(lmess);
    }, [lmess]);

    useEffect(() => {
        setLoadingInfo(linf);
    }, [linf]);

    useEffect(() => {
        setLoadingQuote(lq);
    }, [lq]);

    useEffect(() => {
        setLoadingPublisher(lp);
    }, [lp]);

    const getPageLimit = (allData, dataLimitPerPage) => {
        if (allData % dataLimitPerPage === 0) {
            return allData / dataLimitPerPage;
        } else {
            return Math.floor(allData / dataLimitPerPage) + 1;
        }
    }

    const refresh = () => {
        //TODO
    }

    var styleButtonGroup;
    if (width <= 360) {
        styleButtonGroup = "btn-group btn-group-sm"
    } else {
        styleButtonGroup = "btn-group"
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    const dispatchAll = () => {
        if (menuSwitch == switchConstants.PUBLISHERS_NR) {
            resetPage()
        }

        if (publisherSwitch == publisherswitchConstants.PINNED_NR) {
            dispatch(publisherswitchActions.showPublishers(publisherswitchConstants.ALL_PUBLISHERS))
        }

        if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
            if (ss == spaceswitchConstants.FEED_NR) {
                dispatch(discussionActions.getFeedFilter())
                dispatch(discussionActions.getFeed());
            }
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                dispatch(spaceActions.getSpaceList());
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                dispatch(discussionActions.getMyDiscussionList());
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                dispatch(discussionActions.getLightDiscussionList());
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                dispatch(profilActions.getStarProfilList());
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                dispatch(profilActions.getMyTalkers());
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                dispatch(profilActions.getProfilList("",));
            }
        }

        if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                dispatch(discussionActions.getDiscussionList(urlp, "", false));
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                dispatch(discussionActions.getMyDiscussionList());
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                dispatch(discussionActions.getLightDiscussionList());
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                dispatch(profilActions.getStarProfilList());
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                dispatch(profilActions.getMyTalkers());
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                dispatch(profilActions.getProfilList());
            }
        }

        batch(() => {
            dispatch(socialMediaActions.getSocialMediaList());
            dispatch(socialMediaActions.getSocialMediaListES());
            dispatch(socialMediaActions.getSocialMediaListDE());
            dispatch(socialMediaActions.getSocialMediaListFR());
            dispatch(socialMediaActions.getSocialMediaListPT());
            dispatch(socialMediaActions.getSocialMediaListHI());
            dispatch(socialMediaActions.getSocialMediaListINDO());
            dispatch(socialMediaActions.getSocialMediaListRU());
            dispatch(socialMediaActions.getSocialMediaListCH());
            dispatch(socialMediaActions.getSocialMediaListJP());
            dispatch(socialMediaActions.getSocialMediaListAR());
            dispatch(socialMediaActions.getSocialMediaListIT());
            dispatch(socialMediaActions.getSocialMediaListTR());
            dispatch(socialMediaActions.getSocialMediaListNL());
            dispatch(socialMediaActions.getSocialMediaListGR());
            dispatch(publisherActions.getPublisherList())
            dispatch(quoteActions.getQuotes());

            dispatch(infoActions.getInfos())
            if (authenticated) {
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            }
        })
    }

    // console.log(profilList)
    // console.log("ss : " + ss)

    if (!profilList || !profilList.profils) {
        if (loading) {
            return (
                <>
                    <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                        <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                            <div>
                                <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <Spinner show={loading} />
                                    <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Social Space</h1> {/* light */}
                                    <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                        <div class={styleButtonGroup} role="group" aria-label="Basic radio toggle button group">
                                            {lop == listonespaceswitchConstants.SPACE_LIST_NR ?
                                                <>
                                                    <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio0" autocomplete="off" disabled={true} />
                                                    <label class="btn btn-outline-primary" for="btnradio0"><FeedIcon /></label>
                                                </>
                                                : null}


                                            <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio5" autocomplete="off" disabled={true} />
                                            {lop == listonespaceswitchConstants.SPACE_LIST_NR ? <label class="btn btn-outline-primary" for="btnradio5"><GroupsIcon /></label> : null}
                                            {lop == listonespaceswitchConstants.ONE_SPACE_NR ? <label class="btn btn-outline-primary" for="btnradio5"><LogoSpace name={localStorage.getItem("spacename")} color={theme == "dark" ? "#fff" : "#000"} /></label> : null}

                                            <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio6" autocomplete="off" disabled={true} />
                                            <label class="btn btn-outline-primary" for="btnradio6"><PersonIcon /></label>

                                            <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio7" autocomplete="off" disabled={true} />
                                            <label class="btn btn-outline-primary" for="btnradio7"><TipsAndUpdatesIcon /></label>

                                            <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio8" autocomplete="off" disabled={true} />
                                            <label class="btn btn-outline-primary" for="btnradio8"><StarIcon /></label>

                                            <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio9" autocomplete="off" disabled={true} />
                                            <label class="btn btn-outline-primary" for="btnradio9"><ForumIcon /></label>

                                            <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio10" autocomplete="off" disabled={true} />
                                            <label class="btn btn-outline-primary" for="btnradio10"><PersonSearchIcon /></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        
        // console.log("ss : " + ss)
        
        if (done) {
            if (error) {
                return (
                    <>
                        <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                            <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                                <div>
                                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                        <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Social Space</h1> {/* light */}
                                        <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 1020, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white"><WarningIcon style={{ marginBottom: 5 }} />{" "}This could be a network issue or the App is in maintenance mode. Please refresh the page{" "}<IconButton style={{ color: "#fff" }} onClick={dispatchAll} title="Load News"><RefreshIcon /></IconButton>{" "}or try again later.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
        }
        return (
            <>
                <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                    <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                        <div>
                            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >
                                    Social Space {<ButtonSpinner handleOpenLoadingPopup={handleOpenLoadingPopup} show={
                                        socialLoading ||
                                        socialLoadingES ||
                                        socialLoadingDE ||
                                        socialLoadingFR ||
                                        socialLoadingPT ||
                                        socialLoadingHI ||
                                        socialLoadingINDO ||
                                        socialLoadingRU ||
                                        socialLoadingCH ||
                                        socialLoadingJP ||
                                        socialLoadingAR ||
                                        socialLoadingIT ||
                                        socialLoadingTR ||
                                        socialLoadingNL ||
                                        socialLoadingGR ||
                                        loadingMyAppreciation ||
                                        loadingMessage ||
                                        loadingInfo ||
                                        loadingQuote ||
                                        loadingPublisher
                                    } />}

                                    <DraggableLoadingPopup openLoadingPopup={openLoadingPopup} handleCloseLoadingPopup={handleCloseLoadingPopup} />
                                </h1>
                                <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                    <div class={styleButtonGroup} role="group" aria-label="Basic radio toggle button group">
                                        {lop == listonespaceswitchConstants.SPACE_LIST_NR ?
                                            <>
                                                <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio0" autocomplete="off" disabled={true} />
                                                <label class="btn btn-outline-primary" for="btnradio0"><FeedIcon /></label>
                                            </>
                                            : null}


                                        <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio5" autocomplete="off" disabled={true} />
                                        {lop == listonespaceswitchConstants.SPACE_LIST_NR ? <label class="btn btn-outline-primary" for="btnradio5"><GroupsIcon /></label> : null}
                                        {lop == listonespaceswitchConstants.ONE_SPACE_NR ? <label class="btn btn-outline-primary" for="btnradio5"><LogoSpace name={localStorage.getItem("spacename")} color={theme == "dark" ? "#fff" : "#000"} /></label> : null}

                                        <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio6" autocomplete="off" disabled={true} />
                                        <label class="btn btn-outline-primary" for="btnradio6"><PersonIcon /></label>

                                        <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio7" autocomplete="off" disabled={true} />
                                        <label class="btn btn-outline-primary" for="btnradio7"><TipsAndUpdatesIcon /></label>

                                        <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio8" autocomplete="off" disabled={true} />
                                        <label class="btn btn-outline-primary" for="btnradio8"><StarIcon /></label>

                                        <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio9" autocomplete="off" disabled={true} />
                                        <label class="btn btn-outline-primary" for="btnradio9"><ForumIcon /></label>

                                        <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio10" autocomplete="off" disabled={true} />
                                        <label class="btn btn-outline-primary" for="btnradio10"><PersonSearchIcon /></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {

        //console.log("ss : " + ss)
        
        return (
            <>
                <CustomProfilPagination
                    data={profilList.profils}
                    RenderComponent={ProfilLayout}
                    title="Social Space"
                    pageLimit={getPageLimit(profilList.profils.length, profilList.perpage)}
                    dataLimit={profilList.perpage}
                    search={profilList.search}
                    shareLink={shareLink}
                    // urlparam={discussionList.urlparam}
                />
            </>
        );
    }
}