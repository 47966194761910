import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socialMediaActions } from '../actions';
import { SocialMedia, SocialMediaNews } from './Item';
import { CustomPagination } from './CustomPagination';
import { languageswitchConstants, socialmediaConstants } from '../constants';
import { Spinner } from '../spinner/spinner';
import Swal from 'sweetalert2';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SocialMediaListRender } from './SocialMediaListRender';


export const SocialMediaListAR = () => {
    const dispatch = useDispatch();
    const s = useSelector(state => state.socialmediaAR.socialmediaListAR);
    const [socialmediaListAR, setSocialmedialListAR] = useState(s);
    const loading = useSelector(state => state.socialmediaAR.loading);
    const e = useSelector(state => state.socialmediaAR.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.socialmediaAR.done);
    const [done, setDone] = useState(d);
    var dataLimitPerPage = 15;

    const lang = useSelector(state => state.languageswitch);
    if (lang === languageswitchConstants.ARABIC_NR) {
        return <SocialMediaListRender
            stateList={s}
            socialmediaList={socialmediaListAR}
            setSocialmedialList={setSocialmedialListAR}
            loading={loading}
            errorState={e}
            error={error}
            setError={setError}
            doneState={d}
            done={done}
            setDone={setDone}
            dataLimitPerPage={dataLimitPerPage}
        />
    }
}