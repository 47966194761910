import axios from "axios";

export const gdprdataService = {
    getGDPRData,
    saveGDPRData,
};

function saveGDPRData(data) {
    // console.log("in Service saveGDPRData")
    const body = JSON.stringify(data);

    return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API}/gdprdatarequest`,
        {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
            body: body
        });
}

function getGDPRData() {
    // console.log("in Service getGDPRData")
    return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API}/gdprdatarequest`,
        {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
        });
}

// function saveGDPRData(data) {   
//     const body = JSON.stringify(data);

//     const requestOptions = {
//         headers: { 'Content-Type': 'application/json' }
//     };

//     axios.defaults.baseURL = process.env.REACT_APP_WPOP_BACKEND;

//     return axios.post('/gdprdatarequest', body, requestOptions);
// }