import axios from "axios";
import { workerConstants } from "../constants";

export const spaceService = {
    getSpaceList
};

const COMMUNO = workerConstants.WORKER

function getSpaceList() {
    console.log("in Service spaces")
    return fetch(COMMUNO + "spaces",
        {
            method: 'GET',
            headers: { 'Accept': 'application/json', /*'mode': "no-cors", "Access-Control-Allow-Origin": "*"*/ }
        });
}
