import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { appreciationActions, discussionActions, infoActions, messageActions, quoteActions, socialMediaActions, spaceActions } from '../actions';
import { SocialMedia, SocialMediaNews, SocialMediaNewsPreview } from './Item';
import { ButtonSpinner, CustomPagination } from './CustomPagination';
import { socialmediaConstants } from '../constants';
import { Spinner } from '../spinner/spinner';
import Swal from 'sweetalert2';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton } from '@material-ui/core';
import { DraggableLoadingPopup } from './SpaceItem';


export const SocialMediaListRender = ({
    stateList,
    socialmediaList,
    setSocialmedialList,
    loading,
    errorState,
    error,
    setError,
    doneState,
    done,
    setDone,
    dataLimitPerPage
}) => {

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const dispatch = useDispatch();
    const pw = useSelector(state => state.previewswitch);
    const [previewswitch, setPreviewSwitch] = useState(pw);

    if (window.innerWidth >= 900 && window.innerWidth < 1200) {
        dataLimitPerPage = 15;
    } else if (window.innerWidth >= 1200 && window.innerWidth < 1482) {
        dataLimitPerPage = 14;
    } else if (window.innerWidth >= 1482) {
        dataLimitPerPage = 15;
    }

    const ls = useSelector(state => state.socialmedia.loading);
    const [socialLoading, setSocialLoading] = useState(ls);
    const lses = useSelector(state => state.socialmediaES.loading);
    const [socialLoadingES, setSocialLoadingES] = useState(lses);
    const lsde = useSelector(state => state.socialmediaDE.loading);
    const [socialLoadingDE, setSocialLoadingDE] = useState(lsde);
    const lsfr = useSelector(state => state.socialmediaFR.loading);
    const [socialLoadingFR, setSocialLoadingFR] = useState(lsfr);
    const lspt = useSelector(state => state.socialmediaPT.loading);
    const [socialLoadingPT, setSocialLoadingPT] = useState(lspt);
    const lshi = useSelector(state => state.socialmediaHI.loading);
    const [socialLoadingHI, setSocialLoadingHI] = useState(lshi);
    const lsindo = useSelector(state => state.socialmediaINDO.loading);
    const [socialLoadingINDO, setSocialLoadingINDO] = useState(lsindo);
    const lsru = useSelector(state => state.socialmediaRU.loading);
    const [socialLoadingRU, setSocialLoadingRU] = useState(lsru);
    const lsch = useSelector(state => state.socialmediaCH.loading);
    const [socialLoadingCH, setSocialLoadingCH] = useState(lsch);
    const lsjp = useSelector(state => state.socialmediaJP.loading);
    const [socialLoadingJP, setSocialLoadingJP] = useState(lsjp);
    const lsar = useSelector(state => state.socialmediaAR.loading);
    const [socialLoadingAR, setSocialLoadingAR] = useState(lsar);
    const lsit = useSelector(state => state.socialmediaIT.loading);
    const [socialLoadingIT, setSocialLoadingIT] = useState(lsit);
    const lstr = useSelector(state => state.socialmediaTR.loading);
    const [socialLoadingTR, setSocialLoadingTR] = useState(lstr);
    const lsnl = useSelector(state => state.socialmediaNL.loading);
    const [socialLoadingNL, setSocialLoadingNL] = useState(lsnl);
    const lsgr = useSelector(state => state.socialmediaGR.loading);
    const [socialLoadingGR, setSocialLoadingGR] = useState(lsgr);
    const lmya = useSelector(state => state.myappreciation.loading);
    const [loadingMyAppreciation, setLoadingMyAppreciation] = useState(lmya);
    const lmess = useSelector(state => state.message.loading);
    const [loadingMessage, setLoadingMessage] = useState(lmess);
    const linf = useSelector(state => state.info.loading);
    const [loadingInfo, setLoadingInfo] = useState(linf);
    const lq = useSelector(state => state.quote.loading);
    const [loadingQuote, setLoadingQuote] = useState(lq);

    const [openLoadingPopup, setOpenLoadingPopup] = useState(false);
    const handleOpenLoadingPopup = (e) => {
        setOpenLoadingPopup(true);
    }
    const handleCloseLoadingPopup = () => {
        setOpenLoadingPopup(false);
    }

    useEffect(() => {
        setSocialmedialList(stateList);
    }, [stateList]);

    useEffect(() => {
        setError(errorState);
    }, [errorState]);

    useEffect(() => {
        setDone(doneState);
    }, [doneState]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        localStorage.setItem("preview", pw)
        setPreviewSwitch(pw);
    }, [pw]);


    useEffect(() => {
        setSocialLoading(ls);
    }, [ls]);

    useEffect(() => {
        setSocialLoadingES(lses);
    }, [lses]);

    useEffect(() => {
        setSocialLoadingDE(lsde);
    }, [lsde]);

    useEffect(() => {
        setSocialLoadingFR(lsfr);
    }, [lsfr]);

    useEffect(() => {
        setSocialLoadingPT(lspt);
    }, [lspt]);

    useEffect(() => {
        setSocialLoadingHI(lshi);
    }, [lshi]);

    useEffect(() => {
        setSocialLoadingINDO(lsindo);
    }, [lsindo]);

    useEffect(() => {
        setSocialLoadingRU(lsru);
    }, [lsru]);

    useEffect(() => {
        setSocialLoadingCH(lsch);
    }, [lsch]);

    useEffect(() => {
        setSocialLoadingJP(lsjp);
    }, [lsjp]);

    useEffect(() => {
        setSocialLoadingAR(lsar);
    }, [lsar]);

    useEffect(() => {
        setSocialLoadingIT(lsit);
    }, [lsit]);

    useEffect(() => {
        setSocialLoadingTR(lstr);
    }, [lstr]);

    useEffect(() => {
        setSocialLoadingNL(lsnl);
    }, [lsnl]);

    useEffect(() => {
        setSocialLoadingGR(lsgr);
    }, [lsgr]);

    useEffect(() => {
        setLoadingMyAppreciation(lmya);
    }, [lmya]);

    useEffect(() => {
        setLoadingMessage(lmess);
    }, [lmess]);

    useEffect(() => {
        setLoadingInfo(linf);
    }, [linf]);

    useEffect(() => {
        setLoadingQuote(lq);
    }, [lq]);

    const getPageLimit = (allData, dataLimitPerPage) => {
        if (allData % dataLimitPerPage === 0) {
            return allData / dataLimitPerPage;
        } else {
            return Math.floor(allData / dataLimitPerPage) + 1;
        }
    }

    const getAllNews = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(socialMediaActions.getSocialMediaList());
                dispatch(socialMediaActions.getSocialMediaListES());
                dispatch(socialMediaActions.getSocialMediaListDE());
                dispatch(socialMediaActions.getSocialMediaListFR());
                dispatch(socialMediaActions.getSocialMediaListPT());
                dispatch(socialMediaActions.getSocialMediaListHI());
                dispatch(socialMediaActions.getSocialMediaListINDO());
                dispatch(socialMediaActions.getSocialMediaListRU());
                dispatch(socialMediaActions.getSocialMediaListCH());
                dispatch(socialMediaActions.getSocialMediaListJP());
                dispatch(socialMediaActions.getSocialMediaListAR());
                dispatch(socialMediaActions.getSocialMediaListIT());
                dispatch(socialMediaActions.getSocialMediaListTR());
                dispatch(socialMediaActions.getSocialMediaListNL());
                dispatch(socialMediaActions.getSocialMediaListGR());
                dispatch(quoteActions.getQuotes());
            })

        }
    }

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(discussionActions.getFeedFilter())
                dispatch(discussionActions.getFeed());
                dispatch(spaceActions.getSpaceList());
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    const refresh = () => {
        dispatch(getAllNews())
        dispatch(getAllInfosForSpace())
    }

    if (!socialmediaList || !socialmediaList.socialmedias) {
        if (loading) {
            return (
                <>
                    <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                        <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                            <div>
                                <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <Spinner show={loading} />
                                    <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Social News</h1> {/* light */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        if (done) {
            if (error) {
                return (
                    <>
                        <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                            <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                                <div>
                                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                        <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Social News</h1> {/* light */}
                                        <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 1020, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white"><WarningIcon style={{ marginBottom: 5 }} />{" "}This could be a network issue or the App is in maintenance mode. Please refresh the page{" "}<IconButton style={{ color: "#fff" }} onClick={() => refresh()} title="Load News"><RefreshIcon /></IconButton>{" "}or try again later.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
        }
        return (
            <>
                <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                    <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                        <div>
                            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >
                                    Social News {<ButtonSpinner handleOpenLoadingPopup={handleOpenLoadingPopup} show={
                                        socialLoading ||
                                        socialLoadingES ||
                                        socialLoadingDE ||
                                        socialLoadingFR ||
                                        socialLoadingPT ||
                                        socialLoadingHI ||
                                        socialLoadingINDO ||
                                        socialLoadingRU ||
                                        socialLoadingCH ||
                                        socialLoadingJP ||
                                        socialLoadingAR ||
                                        socialLoadingIT ||
                                        socialLoadingTR ||
                                        socialLoadingNL ||
                                        socialLoadingGR ||
                                        loadingMyAppreciation ||
                                        loadingMessage ||
                                        loadingInfo ||
                                        loadingQuote
                                    } />}
                                </h1>

                                <DraggableLoadingPopup openLoadingPopup={openLoadingPopup} handleCloseLoadingPopup={handleCloseLoadingPopup} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                {socialmediaList.socialmedias.length > 0 ? (
                    <>
                        <CustomPagination
                            data={socialmediaList.socialmedias}
                            RenderComponent={previewswitch ? SocialMediaNewsPreview : SocialMediaNews}
                            title="Social News"
                            pageLimit={getPageLimit(socialmediaList.socialmedias.length, dataLimitPerPage)}
                            dataLimit={dataLimitPerPage}
                        />
                    </>
                ) : (
                    <>
                        <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                            <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                                <div>
                                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                        <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Social News</h1> {/* light */}
                                        <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 1020, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white"><WarningIcon style={{ marginBottom: 5 }} />{" "}This could be a network issue or the App is in maintenance mode. Please refresh the page{" "}<IconButton style={{ color: "#fff" }} onClick={() => refresh()} title="Load News"><RefreshIcon /></IconButton>{" "}or try again later...</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}