import { useEffect, useState } from "react";
import { WrapperRow } from "./Row";
import { WrapperWeather } from "./WrapperWeather";
import { useSelector } from "react-redux";
import WarningIcon from '@mui/icons-material/Warning';


export const PageNotFound = () => {
    return (
        <>
            <WrapperRow LinksCol={WrapperWeather} RightCol={ContentPageNotFound}>
            </WrapperRow>
        </>
    );
}

const ContentPageNotFound = () => {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
    <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
        <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
            <div>
                <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Page Not Found</h1>
                        <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 1020, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">Oops! It looks like the page you're looking for isn't available. Let's get you back on track</h2>
                </div>
            </div>
        </div>
    </div>)
}

