import axios from "axios";
import { authHeader, authHeaderWithJSON, authHeaderWithMultipart, authHeaderWithoutContentType } from "../helpers";
import { workerConstants } from "../constants";
import { PinClass } from "../models/Pin";

export const publisherService = {
    getPublisherList,
    getMyPublisherList
};


const COMMUNO_PUB = workerConstants.PUB

function getPublisherList(query, search) {
    const requestOptions = {
        headers: authHeader()
    };


    const replacer = (key, value) => {
        if (typeof value === 'undefined')
            return ""
        else return value;
    }
    var pin = new PinClass()
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user && user.uuid) {
        pin.uuid = user.uuid
    }
    var pinInfos = JSON.parse(JSON.stringify(pin, replacer));
    const body = JSON.stringify(pin);

    if (search) {
        return fetch(COMMUNO_PUB + "publishers?q=" + query,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: body
            });
    } else {
        return fetch(COMMUNO_PUB + "publishers",
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: body
            });
    }
}

function getMyPublisherList(query, search) {
    const replacer = (key, value) => {
        if (typeof value === 'undefined')
            return ""
        else return value;
    }
    var pin = new PinClass()
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user && user.uuid) {
        pin.uuid = user.uuid
    }
    pin.onlypinned = true
    var pinInfos = JSON.parse(JSON.stringify(pin, replacer));
    const body = JSON.stringify(pin);

    if (search) {
        return fetch(COMMUNO_PUB + "publishers?q=" + query,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: body
            });
    } else {
        return fetch(COMMUNO_PUB + "publishers",
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: body
            });
    }
}