import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socialMediaActions } from '../actions';
import { Breach } from './Item';
import { CustomPagination } from './CustomPagination';
import Swal from 'sweetalert2';
import { Spinner } from '../spinner/spinner';
import { accountConstants, breachConstants, gdprdataConstants } from '../constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { ThumbUp } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { blue } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from "@material-ui/core/styles";
import { gdprdataActions } from '../actions/gdprdata.actions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Switch } from "@mui/material";
import Draggable from 'react-draggable';


export const GDPRDataRequest = () => {
    const g = useSelector(state => state.gdprdata.gdprdata);
    const [gdprdata, setGDPRData] = useState(g);
    const l = useSelector(state => state.gdprdata.loading);
    const [loading, setLoading] = useState(l);
    const d = useSelector((state) => state.gdprdata.done);
    const [done, setDone] = useState(d)
    const es = useSelector((state) => state.gdprdata.errorcheck);
    const [errors, setErrors] = useState(es);   
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const valueRef = useRef('')
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errorList, setErrorList] = useState({});
    const [error, setError] = useState(false);

    const [confirmGDPR, setConfirmGDPR] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        navigate("/");
    }

    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => {
        setOpen1(false);
    }

    const show = (e) => {
        handleOpen();
    }

    const show1= (e) => {
        handleOpen1();
    }

    useEffect(() => {
        dispatch(gdprdataActions.getGDPRData());
    }, []);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setGDPRData(g);
    }, [g]);

    useEffect(() => {
        setLoading(l);
    }, [l]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 100,
            left: 0,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        // console.log(d)
        // console.log(es)
        if (d) {
            if (es) {
                scrollToTop();
                setError(false);
                show1();
                dispatch({ type: gdprdataConstants.SAVE_FAILURE_DONE })
                // Swal.fire({
                //     icon: 'error',
                //     title: 'An error has occurred. Please try again ...',
                //     showConfirmButton: false,
                //     timer: 3000
                // })
            } else {
                dispatch({ type: gdprdataConstants.GET_DONE })
                show();
            }
        }
    }, [d]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(gdprdata)
        let er = false;
        let erl = {};

        if (!confirmGDPR) {
            erl = { ...erl, confirmGDPR: "This field is required" };
            er = true;
        }

        if (gdprdata.firstname == null || gdprdata.firstname == "") {
            erl = { ...erl, firstname: "This field is required" };
            er = true;
        }

        if (gdprdata.lastname == null || gdprdata.lastname == "") {
            erl = { ...erl, lastname: "This field is required" };
            er = true;
        }

        if (gdprdata.email == null || gdprdata.email == "") {
            erl = { ...erl, email: "This field is required" };
            er = true;
        }

        if (gdprdata.requestas == null || gdprdata.requestas == "") {
            erl = { ...erl, requestas: "This field is required" };
            er = true;
        }

        if (gdprdata.requesttype == null || gdprdata.requesttype == "") {
            erl = { ...erl, requesttype: "This field is required" };
            er = true;
        }

        if (gdprdata.country == null || gdprdata.country == "") {
            erl = { ...erl, country: "This field is required" };
            er = true;
        }

        if (er) {
            scrollToTop();
            toast.error(
                "The GDPR Request is not correctly fulfilled"
            );
            setErrorList(erl);
            setError(er);
        } else {
            dispatch(gdprdataActions.saveGDPRData(gdprdata));
            // navigate("/");
            // show();
        }
    }

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "radio") {
            setGDPRData({ ...gdprdata, ["requestas"]: e.target.value });
        }

        if (e.target.type === "checkbox") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;               
            }           
        } else {
            v = e.target.value;
        }

        if (e.target.name !== "confirmGDPR") {
            setGDPRData({ ...gdprdata, [e.target.name]: v });
            // console.log(gdprdata)
        } else {
            // console.log("changing confirmGDPR")
            setConfirmGDPR(!confirmGDPR);
        }      
    }

    

    return (
        <div class="row" style={{ marginBottom: 50 }}>
            <div class="col-6 col-md-2 marginTopMobil" style={{ marginTop: "120px" }}>
            </div>
            <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                <div id="top" class="marginMobil" style={{ marginRight: "30px", marginLeft: window.innerWidth >= 600 && window.innerWidth < 1200 ? "100px" : "0px" }}>
                    <div>
                        <div class="container">
                            <form>
                                <Spinner show={loading} />
                                <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <a href="#top" className="white" style={{ fontSize: "2.5rem", color: theme === 'dark' ? '#fff' : '#000', textDecoration: "none" }} >GDPR Data Request</a> {/* light */}
                                </div>
                                <div class="row">
                                    <p>You should only fill in this form if:</p>
                                    <ul style={{ marginLeft: 40 }}>
                                        <li><p>You are a data protection authority OR an individual (i.e. data subject);</p></li>
                                        <li><p>You want to contact a company/organisation in order to exercise your rights under the GDPR</p></li>
                                    </ul>
                                    <p>You should NOT fill in this form if:</p>
                                    <ul style={{ marginLeft: 40 }}>
                                        <li><p>Your request is NOT related to the GDPR (e.g. customer service questions)</p></li>
                                        <li><p>You are a company/organization/other legal entity</p></li>
                                    </ul>
                                    <p>
                                        The personal data that you are asked to provide in this form is necessary for OneLike to fulfill its obligations under Article 27 of the GDPR as EU/EEA Representative. The information that you provide will only be used for the purposes of identifying you and/or the person on whose behalf you are making the request and for handling your request. For more information on how OneLike will handle your personal data, please read our <a style={{ textDecoration: 'none', color: '#007bff' }} href="https://social.onelike.app/privacy-policy">Privacy Policy</a>.
                                    </p>
                                    {/* <p>
                                    The information that you provide in this form will be transferred to a country outside of the European Economic Area (EEA) for purposes related to the handling of your request to exercise your rights under the GDPR with respect to one of our clients. This country may not have equivalent data protection laws to the EEA or benefit from an adequacy decision by the EU Commission. By submitting your personal data, you are agreeing to this transfer and processing. We will take necessary steps to ensure that appropriate security measures are taken with the aim of ensuring that your privacy rights continue to be protected as outlined in our <a href="https://social.onelike.app/privacy-policy">Privacy Policy</a>.
                                    </p> */}
                                    <GDPRConfirmation open={open} handleClose={handleClose} />
                                    <GDPRError open={open1} handleClose={handleClose1} />
                                    
                                    {!error ? null : (
                                        <FormHelperText error={true}>Your GDPR request is either incorrect or incomplete. The missing or incorrect details have been highlighted in red. Please review and correct these entries, then resubmit your GDPR request</FormHelperText>
                                    )}

                                    {!errors ? null : (
                                        <FormHelperText style={{marginTop : 20}} error={true}>{errors}</FormHelperText>                                       
                                    )}
                                    <div class="col-6 col-md-6" style={{ marginTop: "50px" }}>
                                        <TextField
                                            id="outlined-basic"
                                            name="firstname"
                                            variant="outlined"
                                            // label=" "
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonIcon style={{ color: "#166fe5" }} />
                                                    </InputAdornment>
                                                ),
                                                style: { color: theme === 'dark' ? '#fff' : '#000' } // light
                                            }}
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }} // Remove the label on the top of the searchbar
                                            inputRef={valueRef}
                                            placeholder={"First Name *"}
                                            onChange={handleChange}
                                            fullWidth
                                            color="info" // dark mode
                                            focused // dark mode                           
                                        // onKeyDown={(e) => {
                                        //     if (e.key === "Enter") {
                                        //         handleSubmit(e);
                                        //     }
                                        // }}
                                        />
                                        {errorList.firstname ? <FormHelperText error={true}>{errorList.firstname}</FormHelperText> : null}
                                    </div>

                                    <div class="col-6 col-md-6" style={{ marginTop: "50px" }}>
                                        <TextField
                                            id="outlined-basic"
                                            name="lastname"
                                            variant="outlined"
                                            // label=" "
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonIcon style={{ color: "#166fe5" }} />
                                                    </InputAdornment>
                                                ),
                                                style: { color: theme === 'dark' ? '#fff' : '#000' } // light
                                            }}
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }} // Remove the label on the top of the searchbar
                                            inputRef={valueRef}
                                            placeholder={"Last Name *"}
                                            onChange = {handleChange}
                                            fullWidth
                                            color="info" // dark mode
                                            focused // dark mode                           
                                        // onKeyDown={(e) => {
                                        //     if (e.key === "Enter") {
                                        //         handleSubmit(e);
                                        //     }
                                        // }}
                                        />
                                        {errorList.lastname ? <FormHelperText error={true}>{errorList.lastname}</FormHelperText> : null}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-12" style={{ marginTop: "20px" }}>
                                        <TextField
                                            id="outlined-basic"
                                            name="email"
                                            variant="outlined"
                                            // label=" "
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonIcon style={{ color: "#166fe5" }} />
                                                    </InputAdornment>
                                                ),
                                                style: { color: theme === 'dark' ? '#fff' : '#000' } // light
                                            }}
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }} // Remove the label on the top of the searchbar
                                            inputRef={valueRef}
                                            placeholder={"Email *"}
                                            onChange = {handleChange}
                                            fullWidth
                                            color="info" // dark mode
                                            focused // dark mode                           
                                        // onKeyDown={(e) => {
                                        //     if (e.key === "Enter") {
                                        //         handleSubmit(e);
                                        //     }
                                        // }}
                                        />
                                        {errorList.email ? <FormHelperText error={true}>{errorList.email}</FormHelperText> : null}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-12" style={{ marginTop: "20px" }}>
                                        <TextField
                                            id="outlined-basic"
                                            name="phone"
                                            variant="outlined"
                                            // label=" "
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonIcon style={{ color: "#166fe5" }} />
                                                    </InputAdornment>
                                                ),
                                                style: { color: theme === 'dark' ? '#fff' : '#000' } // light
                                            }}
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }} // Remove the label on the top of the searchbar
                                            inputRef={valueRef}
                                            placeholder={"Phone Number in international Format (Optional)"}
                                            onChange = {handleChange}
                                            fullWidth
                                            color="info" // dark mode
                                            focused // dark mode                           
                                        // onKeyDown={(e) => {
                                        //     if (e.key === "Enter") {
                                        //         handleSubmit(e);
                                        //     }
                                        // }}
                                        />
                                        {errorList.phone ? <FormHelperText error={true}>{errorList.phone}</FormHelperText> : null}
                                    </div>
                                </div>
                                
                                <CountrySelect gdprdata={gdprdata} handleChange={handleChange} errorList={errorList} theme={theme} />

                                <FormControl style={{ marginTop: "50px", color: "#fff" }}>
                                    <FormLabel id="demo-radio-buttons-group-label" style={{ color : theme == 'dark' ? "#fff" : "#000" }}>I am making this request as (or on behalf of) *</FormLabel>
                                    {errorList.requestas ? <FormHelperText error={true}>{errorList.requestas}</FormHelperText> : null}
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="requestas"
                                        style={{
                                            marginLeft: 30,
                                            marginTop: 20
                                        }}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }}
                                            value="A customer/client/user/patient" control={<Radio sx={{
                                                color: theme == 'dark' ? '#fff' : '#000',
                                            }} />} label="customer" />
                                        <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} value="A website visitor" control={<Radio sx={{
                                            color: theme == 'dark' ? '#fff' : '#000',
                                        }} />} label="A website visitor" />
                                        <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} value="A prospective employee" control={<Radio sx={{
                                            color: theme == 'dark' ? '#fff' : '#000',
                                        }} />} label="A prospective employee" />
                                        <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} value="An employee" control={<Radio sx={{
                                            color: theme == 'dark' ? '#fff' : '#000',
                                        }} />} label="An employee" />
                                        <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} value="A former employee" control={<Radio sx={{
                                            color: theme == 'dark' ? '#fff' : '#000',
                                        }} />} label="A former employee" />
                                        <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} value="A vendor" control={<Radio sx={{
                                            color: theme == 'dark' ? '#fff' : '#000',
                                        }} />} label="A vendor" />
                                        <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} value="An EU/EEA Data Protection Authority" control={<Radio sx={{
                                            color: theme == 'dark' ? '#fff' : '#000',
                                        }} />} label="An EU/EEA Data Protection Authority" />
                                        <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} value="Other" control={<Radio sx={{
                                            color: theme == 'dark' ? '#fff' : '#000',
                                        }} />} label="Other" />
                                    </RadioGroup>
                                </FormControl>

                                <div class="row">
                                    <FormLabel id="demo-radio-buttons-group-label" style={{ color: theme == 'dark' ? "#fff" : "#000", marginTop: 30 }}>Please select the type of request that you would like to make *</FormLabel>
                                    <FormControl
                                        style={{ marginTop: "20px" }}
                                        sx={{
                                            color: '#fff',
                                        }}
                                    >
                                        <Select
                                            variant="outlined"
                                            labelId="demo-simple-select-label"                                            
                                            inputProps={{
                                                name: "requesttype",
                                                id: "requesttype-simple"
                                            }}
                                            value={gdprdata && gdprdata.requesttype ? gdprdata.requesttype : ""}
                                            label="Age"
                                            fullWidth
                                            focused // dark mode
                                            sx={{
                                                color: theme == 'dark' ? '#fff' : '#000',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#007bff',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#007bff',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#007bff',
                                                },
                                                '.MuiSvgIcon-root ': {
                                                    fill: "white !important",
                                                },
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={"Withdrawal of consent"}>Withdrawal of consent</MenuItem>
                                            <MenuItem value={"Access to data"}>Access to data</MenuItem>
                                            <MenuItem value={"Rectification of data"}>Rectification of data</MenuItem>
                                            <MenuItem value={"Deletion of Data"}>Deletion of Data</MenuItem>
                                            <MenuItem value={"Opposition to the processing of data"}>Opposition to the processing of data</MenuItem>
                                            <MenuItem value={"Restriction of the processing of data"}>Restriction of the processing of data</MenuItem>
                                            <MenuItem value={"Review of an automated decision"}>Review of an automated decision</MenuItem>
                                            <MenuItem value={"Data portability"}>Data portability</MenuItem>
                                        </Select>
                                        {errorList.requesttype ? <FormHelperText error={true}>{errorList.requesttype}</FormHelperText> : null}
                                    </FormControl>
                                    <FormControl
                                        style={{ marginTop: "20px" }}
                                        sx={{
                                            color: '#fff',
                                        }}
                                    >
                                        <FormLabel id="demo-radio-buttons-group-label" style={{ color: theme == 'dark' ? "#fff" : "#000", marginTop: 30 }}>Please enter any additional information that will help us handle your request. Please refrain from entering any personal information.</FormLabel>
                                        <TextField
                                            id="filled-multiline-static"
                                            name="extra"
                                            // label="Multiline"
                                            multiline
                                            fullWidth
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            sx={{
                                                color: theme == 'dark' ? '#fff' : '#000',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#007bff',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#007bff',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#007bff',
                                                },
                                                '.MuiSvgIcon-root ': {
                                                    fill: "white !important",
                                                },
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                '.MuiOutlinedInput-input': {
                                                    color: theme == 'dark' ? '#fff' : '#000',
                                                },
                                                '&:hover .MuiOutlinedInput-input': {
                                                    borderColor: '#007bff',
                                                }
                                            }}
                                            style={{ marginTop: "20px" }}
                                            onChange={handleChange}
                                        />
                                    </FormControl>

                                    <FormControl
                                        style={{ marginTop: "20px" }}
                                        sx={{
                                            color: '#fff',
                                        }}
                                    >
                                        <FormGroup>
                                            <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} control={<Checkbox name="confirmGDPR" onChange={(e) => handleChange(e)} value={confirmGDPR}
                                                checked={confirmGDPR}  sx={{
                                                    color: theme == 'dark' ? "#fff" : "#000",
                                            }} />} label="I confirm that I am making this request in relation to personal data which is subject to the GDPR. *" />
                                        </FormGroup>
                                        {errorList.confirmGDPR ? (<FormHelperText error={true}>{errorList.confirmGDPR}</FormHelperText>) : null}                                       
                                    </FormControl>
                                </div>

                                <LoadingButton style={{ marginTop: "20px" }}
                                    onClick={e => handleSubmit(e)} loading={loading} loadingIndicator="Loading…" variant="contained">
                                    Submit
                                </LoadingButton>

                                <p style={{ marginTop: 50 }}>If the form does not work, please send your request to this email address: <a style={{ textDecoration: 'none', color: '#007bff' }} href="mailto:gdpr@onelike.app">gdpr@onelike.app</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        overflowY: 'auto',
        overflowX: 'hidden',
        width: 350,

        '@media (min-width: 600px) and (max-width: 800px)': {
            width: 500
        },

        '@media (min-width: 800px)': {
            width: 600
        },
    },
    customizedButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        float: 'right',
    }
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const GDPRConfirmation = ({ open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (        
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: '#0D1F2D' }} id="draggable-dialog-title">
                    <span style={{ color: '#166fe5' }}>GDPR Request Successfully Submitted</span> {/* light */}
                    {/*<IconButton autoFocus onClick={handleClose} color="primary" className={classes.customizedButton}>
                        <CloseIcon />
                </IconButton> */}
                </DialogTitle>
                <DialogContent style={{ background: theme === 'dark' ? '#0D1F2D' : '#fff' }}> {/* light */}
                    <DialogContentText style={{ color: theme === 'dark' ? '#fff' : '#000' }}>
                        <div style={{ marginTop: 20 }}>
                            <p>Thank you for submitting your GDPR request. Your request has been successfully received and is now being processed</p>
                            <br/>
                            <b><p>What Happens Next?</p></b>
                            <br />
                            <ul>
                                <li><b>Confirmation Email:</b> You will receive a confirmation email shortly, detailing your request</li>
                                <li><b>Review Process:</b>  Our team will review your request and take the necessary steps to address it in accordance with GDPR regulations</li>
                                <li><b>Response Time:</b> We aim to respond to your request within 30 days. Should we need more time due to the complexity of the request, we will inform you accordingly</li>                               
                            </ul>
                            <br />
                            <p>If you have any further questions or need assistance in the meantime, please feel free to contact our support team</p>
                            <br />
                            <b><p>Contact Us at <a href="mailto:gdpr@onelike.app">gdpr@onelike.app</a></p></b>
                            <br/>
                            <p>We appreciate your patience and are committed to protecting your data privacy rights</p>
                        </div>
                    </DialogContentText>{/* light */}
                </DialogContent>
                <DialogActions style={{ background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const GDPRError = ({ open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: '#0D1F2D' }} id="draggable-dialog-title">
                    <span style={{ color: '#166fe5' }}>GDPR Request Successfully Submitted</span> {/* light */}
                    {/*<IconButton autoFocus onClick={handleClose} color="primary" className={classes.customizedButton}>
                        <CloseIcon />
                </IconButton> */}
                </DialogTitle>
                <DialogContent style={{ background: theme === 'dark' ? '#0D1F2D' : '#fff' }}> {/* light */}
                    <DialogContentText style={{ color: theme === 'dark' ? '#fff' : '#000' }}>
                        <div style={{ marginTop: 20 }}>
                            <p>We're sorry, but we were unable to process your GDPR request due to a server issue</p>
                            <p>Please try submitting your request again later</p>
                            <br />
                            <p>If the problem persists, feel free to contact our support team for assistance at <a href="mailto:gdpr@onelike.app">gdpr@onelike.app</a></p>
                            <br />
                            <p>We apologize for the inconvenience and thank you for your patience.</p>
                            <br />                            
                        </div>
                    </DialogContentText>{/* light */}
                </DialogContent>
                <DialogActions style={{ background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyless = makeStyles(theme => ({
    root: {
        display: "block",
    },
    formControl: {
        margin: theme.spacing(1),
    },
    code: {
        borderRight: "1px solid #ccc",
        paddingRight: 8,
        marginRight: 8
    },
    customSelect: {
        "& .code": {
            borderRight: "none",
            paddingRight: 0,
            marginRight: 0
        },
        "& .country": {
            display: "none"
        }
    }
}));

export default function CountrySelect({gdprdata, handleChange, errorList, theme}) {
    const classes = useStyless();
    const [values, setValues] = React.useState({
        age: "",
        name: "hai"
    });

    // const handleChange = event => {
    //     setValues(oldValues => {
    //         console.log(event.target.value, event.target.name);
    //         return {
    //             ...oldValues,
    //             [event.target.name]: event.target.value
    //         };
    //     });
    // };

    return (
        <div class="row">
            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ marginTop: "20px" }}>
                <FormControl fullWidth>
                    <FormLabel id="demo-radio-buttons-group-label" style={{ color: theme == 'dark' ? "#fff" : "#000", marginTop: 30 }}>Please select the country where you are/you were located *</FormLabel>
                    <Select
                        value={gdprdata && gdprdata.country ? gdprdata.country : ""}
                        onChange={handleChange}
                        inputProps={{
                            name: "country",
                            id: "country-simple"
                        }}
                        fullWidth
                        focused // dark mode
                        sx={{
                            color: theme == 'dark' ? '#fff' : '#000',
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#007bff',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#007bff',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#007bff',
                            },
                            '.MuiSvgIcon-root ': {
                                fill: "white !important",
                            },
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                        style={{ marginTop: "20px" }}
                    >
                        <MenuItem value={"Afghanistan"}>
                            <span className={`country`}>Afghanistan</span>
                        </MenuItem>
                        <MenuItem value={"Albania"}>
                            <span className={`country`}>Albania</span>
                        </MenuItem>
                        <MenuItem value={"Algeria"}>
                            <span className={`country`}>Algeria</span>
                        </MenuItem>
                        <MenuItem value={"American Samoa"}>
                            <span className={`country`}>American Samoa</span>
                        </MenuItem>
                        <MenuItem value={"Andorra"}>
                            <span className={`country`}>Andorra</span>
                        </MenuItem>
                        <MenuItem value={"Angola"}>
                            <span className={`country`}>Angola</span>
                        </MenuItem>
                        <MenuItem value={"Anguilla"}>
                            <span className={`country`}>Anguilla</span>
                        </MenuItem>
                        <MenuItem value={"Antarctica"}>
                            <span className={`country`}>Antarctica</span>
                        </MenuItem>
                        <MenuItem value={"Antigua and Barbuda"}>
                            <span className={`country`}>Antigua and Barbuda</span>
                        </MenuItem>
                        <MenuItem value={"Argentina"}>
                            <span className={`country`}>Argentina</span>
                        </MenuItem>
                        <MenuItem value={"Armenia"}>
                            <span className={`country`}>Armenia</span>
                        </MenuItem>
                        <MenuItem value={"Aruba"}>
                            <span className={`country`}>Aruba</span>
                        </MenuItem>
                        <MenuItem value={"Australia"}>
                            <span className={`country`}>Australia</span>
                        </MenuItem>
                        <MenuItem value={"Austria"}>
                            <span className={`country`}>Austria</span>
                        </MenuItem>
                        <MenuItem value={"Azerbaijan"}>
                            <span className={`country`}>Azerbaijan</span>
                        </MenuItem>
                        <MenuItem value={"Bahamas"}>
                            <span className={`country`}>Bahamas</span>
                        </MenuItem>
                        <MenuItem value={"Bahrain"}>
                            <span className={`country`}>Bahrain</span>
                        </MenuItem>
                        <MenuItem value={"Bangladesh"}>
                            <span className={`country`}>Bangladesh</span>
                        </MenuItem>
                        <MenuItem value={"Barbados"}>
                            <span className={`country`}>Barbados</span>
                        </MenuItem>
                        <MenuItem value={"Belarus"}>
                            <span className={`country`}>Belarus</span>
                        </MenuItem>
                        <MenuItem value={"Belgium"}>
                            <span className={`country`}>Belgium</span>
                        </MenuItem>
                        <MenuItem value={"Belize"}>
                            <span className={`country`}>Belize</span>
                        </MenuItem>
                        <MenuItem value={"Benin"}>
                            <span className={`country`}>Benin</span>
                        </MenuItem>
                        <MenuItem value={"Bermuda"}>
                            <span className={`country`}>Bermuda</span>
                        </MenuItem>
                        <MenuItem value={"Bhutan"}>
                            <span className={`country`}>Bhutan</span>
                        </MenuItem>
                        <MenuItem value={"Bolivia"}>
                            <span className={`country`}>Bolivia</span>
                        </MenuItem>
                        <MenuItem value={"Bosnia and Herzegovina"}>
                            <span className={`country`}>Bosnia and Herzegovina</span>
                        </MenuItem>
                        <MenuItem value={"Botswana"}>
                            <span className={`country`}>Botswana</span>
                        </MenuItem>
                        <MenuItem value={"Brazil"}>
                            <span className={`country`}>Brazil</span>
                        </MenuItem>
                        <MenuItem value={"British Indian Ocean Territory"}>
                            <span className={`country`}>British Indian Ocean Territory</span>
                        </MenuItem>
                        <MenuItem value={"British Virgin Islands"}>
                            <span className={`country`}>British Virgin Islands</span>
                        </MenuItem>
                        <MenuItem value={"Brunei"}>
                            <span className={`country`}>Brunei</span>
                        </MenuItem>
                        <MenuItem value={"Bulgaria"}>
                            <span className={`country`}>Bulgaria</span>
                        </MenuItem>
                        <MenuItem value={"Burkina Faso"}>
                            <span className={`country`}>Burkina Faso</span>
                        </MenuItem>
                        <MenuItem value={"Burundi"}>
                            <span className={`country`}>Burundi</span>
                        </MenuItem>
                        <MenuItem value={"Cambodia"}>
                            <span className={`country`}>Cambodia</span>
                        </MenuItem>
                        <MenuItem value={"Cameroon"}>
                            <span className={`country`}>Cameroon</span>
                        </MenuItem>
                        <MenuItem value={"Canada"}>
                            <span className={`country`}>Canada</span>
                        </MenuItem>
                        <MenuItem value={"Cape Verde"}>
                            <span className={`country`}>Cape Verde</span>
                        </MenuItem>
                        <MenuItem value={"Cayman Islands"}>
                            <span className={`country`}>Cayman Islands</span>
                        </MenuItem>
                        <MenuItem value={"Central African Republic"}>
                            <span className={`country`}>Central African Republic</span>
                        </MenuItem>
                        <MenuItem value={"Chad"}>
                            <span className={`country`}>Chad</span>
                        </MenuItem>
                        <MenuItem value={"Chile"}>
                            <span className={`country`}>Chile</span>
                        </MenuItem>
                        <MenuItem value={"China"}>
                            <span className={`country`}>China</span>
                        </MenuItem>
                        <MenuItem value={"Christmas Island"}>
                            <span className={`country`}>Christmas Island</span>
                        </MenuItem>
                        <MenuItem value={"Cocos Islands"}>
                            <span className={`country`}>Cocos Islands</span>
                        </MenuItem>
                        <MenuItem value={"Colombia"}>
                            <span className={`country`}>Colombia</span>
                        </MenuItem>
                        <MenuItem value={"Comoros"}>
                            <span className={`country`}>Comoros</span>
                        </MenuItem>
                        <MenuItem value={"Cook Islands"}>
                            <span className={`country`}>Cook Islands</span>
                        </MenuItem>
                        <MenuItem value={"Costa Rica"}>
                            <span className={`country`}>Costa Rica</span>
                        </MenuItem>
                        <MenuItem value={"Croatia"}>
                            <span className={`country`}>Croatia</span>
                        </MenuItem>
                        <MenuItem value={"Cuba"}>
                            <span className={`country`}>Cuba</span>
                        </MenuItem>
                        <MenuItem value={"Curacao"}>
                            <span className={`country`}>Curacao</span>
                        </MenuItem>
                        <MenuItem value={"Cyprus"}>
                            <span className={`country`}>Cyprus</span>
                        </MenuItem>
                        <MenuItem value={"Czech Republic"}>
                            <span className={`country`}>Czech Republic</span>
                        </MenuItem>
                        <MenuItem value={"Democratic Republic of the Congo"}>
                            <span className={`country`}>Democratic Republic of the Congo</span>
                        </MenuItem>
                        <MenuItem value={"Denmark"}>
                            <span className={`country`}>Denmark</span>
                        </MenuItem>
                        <MenuItem value={"Djibouti"}>
                            <span className={`country`}>Djibouti</span>
                        </MenuItem>
                        <MenuItem value={"Dominica"}>
                            <span className={`country`}>Dominica</span>
                        </MenuItem>
                        <MenuItem value={"Dominican Republic"}>
                            <span className={`country`}>Dominican Republic</span>
                        </MenuItem>
                        <MenuItem value={"East Timor"}>
                            <span className={`country`}>East Timor</span>
                        </MenuItem>
                        <MenuItem value={"Ecuador"}>
                            <span className={`country`}>Ecuador</span>
                        </MenuItem>
                        <MenuItem value={"Egypt"}>
                            <span className={`country`}>Egypt</span>
                        </MenuItem>
                        <MenuItem value={"El Salvador"}>
                            <span className={`country`}>El Salvador</span>
                        </MenuItem>
                        <MenuItem value={"Equatorial Guinea"}>
                            <span className={`country`}>Equatorial Guinea</span>
                        </MenuItem>
                        <MenuItem value={"Eritrea"}>
                            <span className={`country`}>Eritrea</span>
                        </MenuItem>
                        <MenuItem value={"Estonia"}>
                            <span className={`country`}>Estonia</span>
                        </MenuItem>
                        <MenuItem value={"Ethiopia"}>
                            <span className={`country`}>Ethiopia</span>
                        </MenuItem>
                        <MenuItem value={"Falkland Islands"}>
                            <span className={`country`}>Falkland Islands</span>
                        </MenuItem>
                        <MenuItem value={"Faroe Islands"}>
                            <span className={`country`}>Faroe Islands</span>
                        </MenuItem>
                        <MenuItem value={"Fiji"}>
                            <span className={`country`}>Fiji</span>
                        </MenuItem>
                        <MenuItem value={"Finland"}>
                            <span className={`country`}>Finland</span>
                        </MenuItem>
                        <MenuItem value={"France"}>
                            <span className={`country`}>France</span>
                        </MenuItem>
                        <MenuItem value={"French Polynesia"}>
                            <span className={`country`}>French Polynesia</span>
                        </MenuItem>
                        <MenuItem value={"Gabon"}>
                            <span className={`country`}>Gabon</span>
                        </MenuItem>
                        <MenuItem value={"Gambia"}>
                            <span className={`country`}>Gambia</span>
                        </MenuItem>
                        <MenuItem value={"Georgia"}>
                            <span className={`country`}>Georgia</span>
                        </MenuItem>
                        <MenuItem value={"Germany"}>
                            <span className={`country`}>Germany</span>
                        </MenuItem>
                        <MenuItem value={"Ghana"}>
                            <span className={`country`}>Ghana</span>
                        </MenuItem>
                        <MenuItem value={"Gibraltar"}>
                            <span className={`country`}>Gibraltar</span>
                        </MenuItem>
                        <MenuItem value={"Greece"}>
                            <span className={`country`}>Greece</span>
                        </MenuItem>
                        <MenuItem value={"Greenland"}>
                            <span className={`country`}>Greenland</span>
                        </MenuItem>
                        <MenuItem value={"Grenada"}>
                            <span className={`country`}>Grenada</span>
                        </MenuItem>
                        <MenuItem value={"Guam"}>
                            <span className={`country`}>Guam</span>
                        </MenuItem>
                        <MenuItem value={"Guatemala"}>
                            <span className={`country`}>Guatemala</span>
                        </MenuItem>
                        <MenuItem value={"Guernsey"}>
                            <span className={`country`}>Guernsey</span>
                        </MenuItem>
                        <MenuItem value={"Guinea"}>
                            <span className={`country`}>Guinea</span>
                        </MenuItem>
                        <MenuItem value={"Guinea-Bissau"}>
                            <span className={`country`}>Guinea-Bissau</span>
                        </MenuItem>
                        <MenuItem value={"Guyana"}>
                            <span className={`country`}>Guyana</span>
                        </MenuItem>
                        <MenuItem value={"Haiti"}>
                            <span className={`country`}>Haiti</span>
                        </MenuItem>
                        <MenuItem value={"Honduras"}>
                            <span className={`country`}>Honduras</span>
                        </MenuItem>
                        <MenuItem value={"Hong Kong"}>
                            <span className={`country`}>Hong Kong</span>
                        </MenuItem>
                        <MenuItem value={"Hungary"}>
                            <span className={`country`}>Hungary</span>
                        </MenuItem>
                        <MenuItem value={"Iceland"}>
                            <span className={`country`}>Iceland</span>
                        </MenuItem>
                        <MenuItem value={"India"}>
                            <span className={`country`}>India</span>
                        </MenuItem>
                        <MenuItem value={"Indonesia"}>
                            <span className={`country`}>Indonesia</span>
                        </MenuItem>
                        <MenuItem value={"Iran"}>
                            <span className={`country`}>Iran</span>
                        </MenuItem>
                        <MenuItem value={"Iraq"}>
                            <span className={`country`}>Iraq</span>
                        </MenuItem>
                        <MenuItem value={"Ireland"}>
                            <span className={`country`}>Ireland</span>
                        </MenuItem>
                        <MenuItem value={"Isle of Man"}>
                            <span className={`country`}>Isle of Man</span>
                        </MenuItem>
                        <MenuItem value={"Israel"}>
                            <span className={`country`}>Israel</span>
                        </MenuItem>
                        <MenuItem value={"Italy"}>
                            <span className={`country`}>Italy</span>
                        </MenuItem>
                        <MenuItem value={"Ivory Coast"}>
                            <span className={`country`}>Ivory Coast</span>
                        </MenuItem>
                        <MenuItem value={"Jamaica"}>
                            <span className={`country`}>Jamaica</span>
                        </MenuItem>
                        <MenuItem value={"Japan"}>
                            <span className={`country`}>Japan</span>
                        </MenuItem>
                        <MenuItem value={"Jersey"}>
                            <span className={`country`}>Jersey</span>
                        </MenuItem>
                        <MenuItem value={"Jordan"}>
                            <span className={`country`}>Jordan</span>
                        </MenuItem>
                        <MenuItem value={"Kazakhstan"}>
                            <span className={`country`}>Kazakhstan</span>
                        </MenuItem>
                        <MenuItem value={"Kenya"}>
                            <span className={`country`}>Kenya</span>
                        </MenuItem>
                        <MenuItem value={"Kiribati"}>
                            <span className={`country`}>Kiribati</span>
                        </MenuItem>
                        <MenuItem value={"Kosovo"}>
                            <span className={`country`}>Kosovo</span>
                        </MenuItem>
                        <MenuItem value={"Kuwait"}>
                            <span className={`country`}>Kuwait</span>
                        </MenuItem>
                        <MenuItem value={"Kyrgyzstan"}>
                            <span className={`country`}>Kyrgyzstan</span>
                        </MenuItem>
                        <MenuItem value={"Laos"}>
                            <span className={`country`}>Laos</span>
                        </MenuItem>
                        <MenuItem value={"Latvia"}>
                            <span className={`country`}>Latvia</span>
                        </MenuItem>
                        <MenuItem value={"Lebanon"}>
                            <span className={`country`}>Lebanon</span>
                        </MenuItem>
                        <MenuItem value={"Lesotho"}>
                            <span className={`country`}>Lesotho</span>
                        </MenuItem>
                        <MenuItem value={"Liberia"}>
                            <span className={`country`}>Liberia</span>
                        </MenuItem>
                        <MenuItem value={"Libya"}>
                            <span className={`country`}>Libya</span>
                        </MenuItem>
                        <MenuItem value={"Liechtenstein"}>
                            <span className={`country`}>Liechtenstein</span>
                        </MenuItem>
                        <MenuItem value={"Lithuania"}>
                            <span className={`country`}>Lithuania</span>
                        </MenuItem>
                        <MenuItem value={"Luxembourg"}>
                            <span className={`country`}>Luxembourg</span>
                        </MenuItem>
                        <MenuItem value={"Macau"}>
                            <span className={`country`}>Macau</span>
                        </MenuItem>
                        <MenuItem value={"Macedonia"}>
                            <span className={`country`}>Macedonia</span>
                        </MenuItem>
                        <MenuItem value={"Madagascar"}>
                            <span className={`country`}>Madagascar</span>
                        </MenuItem>
                        <MenuItem value={"Malawi"}>
                            <span className={`country`}>Malawi</span>
                        </MenuItem>
                        <MenuItem value={"Malaysia"}>
                            <span className={`country`}>Malaysia</span>
                        </MenuItem>
                        <MenuItem value={"Maldives"}>
                            <span className={`country`}>Maldives</span>
                        </MenuItem>
                        <MenuItem value={"Mali"}>
                            <span className={`country`}>Mali</span>
                        </MenuItem>
                        <MenuItem value={"Malta"}>
                            <span className={`country`}>Malta</span>
                        </MenuItem>
                        <MenuItem value={"Marshall Islands"}>
                            <span className={`country`}>Marshall Islands</span>
                        </MenuItem>
                        <MenuItem value={"Mauritania"}>
                            <span className={`country`}>Mauritania</span>
                        </MenuItem>
                        <MenuItem value={"Mauritius"}>
                            <span className={`country`}>Mauritius</span>
                        </MenuItem>
                        <MenuItem value={"Mayotte"}>
                            <span className={`country`}>Mayotte</span>
                        </MenuItem>
                        <MenuItem value={"Mexico"}>
                            <span className={`country`}>Mexico</span>
                        </MenuItem>
                        <MenuItem value={"Micronesia"}>
                            <span className={`country`}>Micronesia</span>
                        </MenuItem>
                        <MenuItem value={"Moldova"}>
                            <span className={`country`}>Moldova</span>
                        </MenuItem>
                        <MenuItem value={"Monaco"}>
                            <span className={`country`}>Monaco</span>
                        </MenuItem>
                        <MenuItem value={"Mongolia"}>
                            <span className={`country`}>Mongolia</span>
                        </MenuItem>
                        <MenuItem value={"Montenegro"}>
                            <span className={`country`}>Montenegro</span>
                        </MenuItem>
                        <MenuItem value={"Montserrat"}>
                            <span className={`country`}>Montserrat</span>
                        </MenuItem>
                        <MenuItem value={"Morocco"}>
                            <span className={`country`}>Morocco</span>
                        </MenuItem>
                        <MenuItem value={"Mozambique"}>
                            <span className={`country`}>Mozambique</span>
                        </MenuItem>
                        <MenuItem value={"Myanmar"}>
                            <span className={`country`}>Myanmar</span>
                        </MenuItem>
                        <MenuItem value={"Namibia"}>
                            <span className={`country`}>Namibia</span>
                        </MenuItem>
                        <MenuItem value={"Nauru"}>
                            <span className={`country`}>Nauru</span>
                        </MenuItem>
                        <MenuItem value={"Nepal"}>
                            <span className={`country`}>Nepal</span>
                        </MenuItem>
                        <MenuItem value={"Netherlands"}>
                            <span className={`country`}>Netherlands</span>
                        </MenuItem>
                        <MenuItem value={"Netherlands Antilles"}>
                            <span className={`country`}>Netherlands Antilles</span>
                        </MenuItem>
                        <MenuItem value={"New Caledonia"}>
                            <span className={`country`}>New Caledonia</span>
                        </MenuItem>
                        <MenuItem value={"New Zealand"}>
                            <span className={`country`}>New Zealand</span>
                        </MenuItem>
                        <MenuItem value={"Nicaragua"}>
                            <span className={`country`}>Nicaragua</span>
                        </MenuItem>
                        <MenuItem value={"Niger"}>
                            <span className={`country`}>Niger</span>
                        </MenuItem>
                        <MenuItem value={"Nigeria"}>
                            <span className={`country`}>Nigeria</span>
                        </MenuItem>
                        <MenuItem value={"Niue"}>
                            <span className={`country`}>Niue</span>
                        </MenuItem>
                        <MenuItem value={"North Korea"}>
                            <span className={`country`}>North Korea</span>
                        </MenuItem>
                        <MenuItem value={"Northern Mariana Islands"}>
                            <span className={`country`}>Northern Mariana Islands</span>
                        </MenuItem>
                        <MenuItem value={"Norway"}>
                            <span className={`country`}>Norway</span>
                        </MenuItem>
                        <MenuItem value={"Oman"}>
                            <span className={`country`}>Oman</span>
                        </MenuItem>
                        <MenuItem value={"Pakistan"}>
                            <span className={`country`}>Pakistan</span>
                        </MenuItem>
                        <MenuItem value={"Palau"}>
                            <span className={`country`}>Palau</span>
                        </MenuItem>
                        <MenuItem value={"Palestine"}>
                            <span className={`country`}>Palestine</span>
                        </MenuItem>
                        <MenuItem value={"Panama"}>
                            <span className={`country`}>Panama</span>
                        </MenuItem>
                        <MenuItem value={"Papua New Guinea"}>
                            <span className={`country`}>Papua New Guinea</span>
                        </MenuItem>
                        <MenuItem value={"Paraguay"}>
                            <span className={`country`}>Paraguay</span>
                        </MenuItem>
                        <MenuItem value={"Peru"}>
                            <span className={`country`}>Peru</span>
                        </MenuItem>
                        <MenuItem value={"Philippines"}>
                            <span className={`country`}>Philippines</span>
                        </MenuItem>
                        <MenuItem value={"Pitcairn"}>
                            <span className={`country`}>Pitcairn</span>
                        </MenuItem>
                        <MenuItem value={"Poland"}>
                            <span className={`country`}>Poland</span>
                        </MenuItem>
                        <MenuItem value={"Portugal"}>
                            <span className={`country`}>Portugal</span>
                        </MenuItem>
                        <MenuItem value={"Puerto Rico"}>
                            <span className={`country`}>Puerto Rico</span>
                        </MenuItem>
                        <MenuItem value={"Qatar"}>
                            <span className={`country`}>Qatar</span>
                        </MenuItem>
                        <MenuItem value={"Republic of the Congo"}>
                            <span className={`country`}>Republic of the Congo</span>
                        </MenuItem>
                        <MenuItem value={"Reunion"}>
                            <span className={`country`}>Reunion</span>
                        </MenuItem>
                        <MenuItem value={"Romania"}>
                            <span className={`country`}>Romania</span>
                        </MenuItem>
                        <MenuItem value={"Russia"}>
                            <span className={`country`}>Russia</span>
                        </MenuItem>
                        <MenuItem value={"Rwanda"}>
                            <span className={`country`}>Rwanda</span>
                        </MenuItem>
                        <MenuItem value={"Saint Barthelemy"}>
                            <span className={`country`}>Saint Barthelemy</span>
                        </MenuItem>
                        <MenuItem value={"Saint Helena"}>
                            <span className={`country`}>Saint Helena</span>
                        </MenuItem>
                        <MenuItem value={"Saint Kitts and Nevis"}>
                            <span className={`country`}>Saint Kitts and Nevis</span>
                        </MenuItem>
                        <MenuItem value={"Saint Lucia"}>
                            <span className={`country`}>Saint Lucia</span>
                        </MenuItem>
                        <MenuItem value={"Saint Martin"}>
                            <span className={`country`}>Saint Martin</span>
                        </MenuItem>
                        <MenuItem value={"Saint Pierre and Miquelon"}>
                            <span className={`country`}>Saint Pierre and Miquelon</span>
                        </MenuItem>
                        <MenuItem value={"Saint Vincent and the Grenadines"}>
                            <span className={`country`}>Saint Vincent and the Grenadines</span>
                        </MenuItem>
                        <MenuItem value={"Samoa"}>
                            <span className={`country`}>Samoa</span>
                        </MenuItem>
                        <MenuItem value={"San Marino"}>
                            <span className={`country`}>San Marino</span>
                        </MenuItem>
                        <MenuItem value={"Sao Tome and Principe"}>
                            <span className={`country`}>Sao Tome and Principe</span>
                        </MenuItem>
                        <MenuItem value={"Saudi Arabia"}>
                            <span className={`country`}>Saudi Arabia</span>
                        </MenuItem>
                        <MenuItem value={"Senegal"}>
                            <span className={`country`}>Senegal</span>
                        </MenuItem>
                        <MenuItem value={"Serbia"}>
                            <span className={`country`}>Serbia</span>
                        </MenuItem>
                        <MenuItem value={"Seychelles"}>
                            <span className={`country`}>Seychelles</span>
                        </MenuItem>
                        <MenuItem value={"Sierra Leone"}>
                            <span className={`country`}>Sierra Leone</span>
                        </MenuItem>
                        <MenuItem value={"Singapore"}>
                            <span className={`country`}>Singapore</span>
                        </MenuItem>
                        <MenuItem value={"Sint Maarten"}>
                            <span className={`country`}>Sint Maarten</span>
                        </MenuItem>
                        <MenuItem value={"Slovakia"}>
                            <span className={`country`}>Slovakia</span>
                        </MenuItem>
                        <MenuItem value={"Slovenia"}>
                            <span className={`country`}>Slovenia</span>
                        </MenuItem>
                        <MenuItem value={"Solomon Islands"}>
                            <span className={`country`}>Solomon Islands</span>
                        </MenuItem>
                        <MenuItem value={"Somalia"}>
                            <span className={`country`}>Somalia</span>
                        </MenuItem>
                        <MenuItem value={"South Africa"}>
                            <span className={`country`}>South Africa</span>
                        </MenuItem>
                        <MenuItem value={"South Korea"}>
                            <span className={`country`}>South Korea</span>
                        </MenuItem>
                        <MenuItem value={"South Sudan"}>
                            <span className={`country`}>South Sudan</span>
                        </MenuItem>
                        <MenuItem value={"Spain"}>
                            <span className={`country`}>Spain</span>
                        </MenuItem>
                        <MenuItem value={"Sri Lanka"}>
                            <span className={`country`}>Sri Lanka</span>
                        </MenuItem>
                        <MenuItem value={"Sudan"}>
                            <span className={`country`}>Sudan</span>
                        </MenuItem>
                        <MenuItem value={"Suriname"}>
                            <span className={`country`}>Suriname</span>
                        </MenuItem>
                        <MenuItem value={"Svalbard and Jan Mayen"}>
                            <span className={`country`}>Svalbard and Jan Mayen</span>
                        </MenuItem>
                        <MenuItem value={"Swaziland"}>
                            <span className={`country`}>Swaziland</span>
                        </MenuItem>
                        <MenuItem value={"Sweden"}>
                            <span className={`country`}>Sweden</span>
                        </MenuItem>
                        <MenuItem value={"Switzerland"}>
                            <span className={`country`}>Switzerland</span>
                        </MenuItem>
                        <MenuItem value={"Syria"}>
                            <span className={`country`}>Syria</span>
                        </MenuItem>
                        <MenuItem value={"Taiwan"}>
                            <span className={`country`}>Taiwan</span>
                        </MenuItem>
                        <MenuItem value={"Tajikistan"}>
                            <span className={`country`}>Tajikistan</span>
                        </MenuItem>
                        <MenuItem value={"Tanzania"}>
                            <span className={`country`}>Tanzania</span>
                        </MenuItem>
                        <MenuItem value={"Thailand"}>
                            <span className={`country`}>Thailand</span>
                        </MenuItem>
                        <MenuItem value={"Togo"}>
                            <span className={`country`}>Togo</span>
                        </MenuItem>
                        <MenuItem value={"Tokelau"}>
                            <span className={`country`}>Tokelau</span>
                        </MenuItem>
                        <MenuItem value={"Tonga"}>
                            <span className={`country`}>Tonga</span>
                        </MenuItem>
                        <MenuItem value={"Trinidad and Tobago"}>
                            <span className={`country`}>Trinidad and Tobago</span>
                        </MenuItem>
                        <MenuItem value={"Tunisia"}>
                            <span className={`country`}>Tunisia</span>
                        </MenuItem>
                        <MenuItem value={"Turkey"}>
                            <span className={`country`}>Turkey</span>
                        </MenuItem>
                        <MenuItem value={"Turkmenistan"}>
                            <span className={`country`}>Turkmenistan</span>
                        </MenuItem>
                        <MenuItem value={"Turks and Caicos Islands"}>
                            <span className={`country`}>Turks and Caicos Islands</span>
                        </MenuItem>
                        <MenuItem value={"Tuvalu"}>
                            <span className={`country`}>Tuvalu</span>
                        </MenuItem>
                        <MenuItem value={"U.S. Virgin Islands"}>
                            <span className={`country`}>U.S. Virgin Islands</span>
                        </MenuItem>
                        <MenuItem value={"Uganda"}>
                            <span className={`country`}>Uganda</span>
                        </MenuItem>
                        <MenuItem value={"Ukraine"}>
                            <span className={`country`}>Ukraine</span>
                        </MenuItem>
                        <MenuItem value={"United Arab Emirates"}>
                            <span className={`country`}>United Arab Emirates</span>
                        </MenuItem>
                        <MenuItem value={"United Kingdom"}>
                            <span className={`country`}>United Kingdom</span>
                        </MenuItem>
                        <MenuItem value={"United States"}>
                            <span className={`country`}>United States</span>
                        </MenuItem>
                        <MenuItem value={"Uruguay"}>
                            <span className={`country`}>Uruguay</span>
                        </MenuItem>
                        <MenuItem value={"Uzbekistan"}>
                            <span className={`country`}>Uzbekistan</span>
                        </MenuItem>
                        <MenuItem value={"Vanuatu"}>
                            <span className={`country`}>Vanuatu</span>
                        </MenuItem>
                        <MenuItem value={"Vatican"}>
                            <span className={`country`}>Vatican</span>
                        </MenuItem>
                        <MenuItem value={"Venezuela"}>
                            <span className={`country`}>Venezuela</span>
                        </MenuItem>
                        <MenuItem value={"Vietnam"}>
                            <span className={`country`}>Vietnam</span>
                        </MenuItem>
                        <MenuItem value={"Wallis and Futuna"}>
                            <span className={`country`}>Wallis and Futuna</span>
                        </MenuItem>
                        <MenuItem value={"Western Sahara"}>
                            <span className={`country`}>Western Sahara</span>
                        </MenuItem>
                        <MenuItem value={"Yemen"}>
                            <span className={`country`}>Yemen</span>
                        </MenuItem>
                        <MenuItem value={"Zambia"}>
                            <span className={`country`}>Zambia</span>
                        </MenuItem>
                        <MenuItem value={"Zimbabwe"}>
                            <span className={`country`}>Zimbabwe</span>
                        </MenuItem>
                    </Select>
                    {errorList.country ? (<FormHelperText error={true}>{errorList.country}</FormHelperText>) : null}
                </FormControl>
            </div>
        </div>
    );
}