import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socialMediaActions } from '../actions';
import { Breach } from './Item';
import { CustomPagination } from './CustomPagination';
import Swal from 'sweetalert2';
import { Spinner } from '../spinner/spinner';
import { accountConstants, breachConstants } from '../constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';


export const TermsOfUseGaming = () => {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div class="row">
            <div class="col-6 col-md-2 marginTopMobil" style={{ marginTop: "120px", marginBottom: 30 }}>
            </div>
            <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                <div id="top" class="marginMobil" style={{ marginRight: "30px", marginLeft: window.innerWidth >= 600 && window.innerWidth < 1200 ? "100px" : "0px" }}>
                    <div>
                        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <a href="#top" className="white" style={{ fontSize: "2.5rem", color: theme === 'dark' ? '#fff' : '#000', textDecoration: "none" }} >Terms and Conditions (For Gaming)</a> {/* light */}
                        </div>

                        <div>                            
                            <p>By downloading or using the game, you agree to be bound by these terms and conditions. We advise you to carefully read them before using the game. You are prohibited from copying or modifying the game, any part of the game, or our trademarks. It is also prohibited to attempt to extract the source code of the game, translate the game into other languages, or create derivative versions. The game, including all trademarks, copyright, database rights, and other intellectual property rights, remains the property of OneLike.</p>

                            <p>The Roos Breaker game collects and processes personal data that you have provided to us to provide our service. It is your responsibility to ensure the security of your phone and access to the game. We recommend that you do not jailbreak or root your phone, as it could make your phone vulnerable to malware, viruses, or malicious programs, compromise your phone’s security features, and cause the Roos Breaker game to malfunction or not work at all.</p>

                            <p>Please note that OneLike cannot be held responsible for certain aspects of the game. Some functions of the game require an active internet connection, and OneLike cannot be held responsible if the game does not function properly without access to Wi-Fi or if you have exhausted your data allowance. If you are using the game outside of an area with Wi-Fi, you may be charged by your mobile provider for data usage during the connection while accessing the game, including roaming data charges if you use the game outside of your home territory (i.e. region or country) without turning off data roaming. By using the game, you are accepting responsibility for any such charges, including those incurred by others who use the game on your device.</p>

                            <p>OneLike cannot be held responsible for the way you use the game or for any loss, direct or indirect, that you may experience as a result of relying on the game's functionality. We endeavor to ensure that the game is updated and correct at all times, but we rely on third parties to provide us with information so that we can make it available to you.</p>

                            <p>We may update the game at any time, and you must download updates if you want to keep playing. OneLike does not promise to update the game so that it is relevant to you or compatible with the Android version that you have installed on your device. We may also stop providing the game and terminate its use at any time without notice. Upon termination, the rights and licenses granted to you in these terms will end, and you must stop using the game and delete it from your device.</p>

                            <p>Changes to these terms and conditions will be posted on this page, and you are advised to review them periodically. These changes are effective immediately upon posting. If you have any questions or suggestions regarding these terms and conditions, please contact us at info@onelike.app.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}