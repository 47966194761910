import React, { useState } from "react";
import { Switch, ThemeSwitch } from "./Switch";
import { LikeButton, Provider } from '@lyket/react';
import { Helmet } from "react-helmet";
import { Weather } from "./Weather";

import { GoogleLogin, useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { alertConstants, userConstants } from "../constants";
import { getErrorMessageFetch } from "../helpers";
import { useLocation, useNavigate } from 'react-router-dom';
import { AdsComponentFixed } from "./AdsComponent";
import { useWindowDimensions } from 'react-native';



export const Header = (props) => {
    const location = useLocation();
    const { height, width } = useWindowDimensions();

    const heightHeader = window.innerWidth <= 480 ? "5vh" : "10vh";
    return (
        <>            
            <ThemeSwitch />
            <div class="container">
                <div style={{ minHeight: heightHeader, minWidth: "100%", margin: "0", padding: "0" }}>
                    <div class="row" style={{ minWidth: "100%" }}>
                        <div class="col-6 col-md-2"></div>
                        <div class="col-12 col-md-10">
                            <div style={{ minHeight: heightHeader, marginTop: 30, height: width >= 995 ? '100px !important' : 'auto' }}>
                                {window.innerWidth >= 995 ? <AdsComponentFixed dataAdSlot='3840866295' currentPath={location.pathname} width={740} padding={8} adWidth={728} adHeight={90} marginBottom={20} /> : null}
                                {/* {window.innerWidth >= 995 ? <Weather /> : null} */}
                                {/* <div class="notMobil"><iframe src="//www.highperformancedisplayformat.com/watchnew?key=9e4832e815e6f1b95655b0e227654c07" width="728" height="90" frameborder="0" scrolling="no"></iframe></div>
                        <div class="mobil notOnDesktop"><iframe src="//www.highperformancedisplayformat.com/watchnew?key=b0c6d949edd10e997e4683547a439a46" width="320" height="50" frameborder="0" scrolling="no"></iframe></div> */}
                            </div>
                            <Switch />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

